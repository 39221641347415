import { slotRecipe, radioCardAnatomy } from "../recipe.anatomy";

export const radioCardSlotRecipe = slotRecipe({
  className: "chakra-radio-card",
  slots: radioCardAnatomy.keys(),
  base: {
    item: {
      borderRadius: "15px",
    }
  },
});

