import React from "react";
import SidebarContent from "./SidebarContent";
import { Box, useColorModeValue } from "../../items";

const Sidebar = () => {
  let variantChange = "0.2s linear";
  let sidebarBg = "none";
  let sidebarRadius = "0px";
  let sidebarMargins = "0px";
  let sidebaropqBg = useColorModeValue("white", "gray.700");
  

  return (
    <Box>
      <Box display={{ sm: "none", xl: "block" }} position="fixed">
        <Box
          bg={sidebarBg}
          backdropFilter={"blur(21px)"}
          transition={variantChange}
          w="260px"
          width="260px"
          maxWidth="260px"
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          h="calc(100vh - 32px)"
          ps="20px"
          pe="20px"
          m={sidebarMargins}
          borderRadius={sidebarRadius}
        >
          <SidebarContent />
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
