import React, {useContext, createContext, useEffect} from "react";
import * as signalR from "src/lib/signalR";
import {HubConnectionState} from "@microsoft/signalr";


export type signalRContextType = {
    onChangeConnection: typeof signalR.onChangeConnection,
    sendMessageWithTimeout: typeof signalR.sendMessageWithTimeout,
    onMessageReceived: typeof signalR.onMessageReceived,
    onConnectState: typeof signalR.onConnectState
}

const SignalRContext = createContext<signalRContextType | undefined>(
    undefined
);

export const useSignalR = () => {
    const context = useContext(SignalRContext);
    if (!context) {
        throw new Error("useSignalR must be used within an DispatchContext");
    }
    return context;
};
export const SignalRProvider:
    React.FC<{ children: React.ReactNode }> = (props) => {

    return (
        <SignalRContext.Provider value={{
            onChangeConnection: signalR.onChangeConnection,
            sendMessageWithTimeout: signalR.sendMessageWithTimeout,
            onMessageReceived: signalR.onMessageReceived,
            onConnectState: signalR.onConnectState
        }}>
            {props.children}
        </SignalRContext.Provider>
    );
};
