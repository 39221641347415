import {useEffect, useState} from "react";
import {AddressTypes} from "src/enums";
import {CustomerAddress} from "src/types/service_request";
import AddressSelect from "./address-select";
import {Card_type} from "./form-controll";
import {address} from "../interfaces/interface.validation";
import {Field, Flex, Input, RadioCardRoot} from "src/components";
import {useRedux} from "src/redux/provider";

function Address({
                     data,
                     isValid,
                 }: {
    data: CustomerAddress;
    isValid: (e: boolean) => void;
}) {
    const {useStore, dispatch, actions} = useRedux();
    const [error_validate, setValidateError] = useState<any[] | null>(null);

    const validate = () => {
        try {
            address.parse(data);
            isValid(true);
            setValidateError(null);
            return true;
        } catch (e: any) {
            setValidateError(JSON.parse(e));
            isValid(false);
            return false;
        }
    };

    useEffect(() => {
        validate();
    }, [data]);

    const validate_result = (
        type?: string
    ): { is_invalid: boolean; error: string } => {
        if (type === undefined) {
            if (error_validate === null) return {is_invalid: false, error: ""};
            return {is_invalid: true, error: ""};
        }

        let error = error_validate?.filter((x) => x.path[0] === type);
        if (error === undefined || error?.length === 0)
            return {is_invalid: false, error: ""};
        return {is_invalid: true, error: error?.map((x) => x.message).join(", ")};
    };

    const handleAddress = (e: any) =>
        dispatch(
            actions.service_request.saveAddress({
                ...e,
                email: useStore.shared.e_mail,
            })
        );
    return (
        <>
            <Flex direction={"column"} gap={6} mb={2}>
                <AddressSelect type={data.type}/>
                <Field
                    label="Stát"
                    required
                    errorText={validate_result("country").error}
                    invalid={validate_result("country").is_invalid}
                >
                    <RadioCardRoot
                        w={"100%"}
                        value={data.country}
                        onChangeCapture={(e: any) =>
                            handleAddress({...data, country: e.target.value})
                        }
                    >
                        <Flex direction={"row"} w={"100%"} gap={4}>
                            <Card_type
                                label={"Česká republika"}
                                value={"CZ"}
                                isPrice={false}
                            />
                            <Card_type
                                label={"Slovenská republika"}
                                value={"SK"}
                                isPrice={false}
                            />
                        </Flex>
                    </RadioCardRoot>
                </Field>
                <Flex direction={{base: "column", md: "row"}} gap={4}>
                    {(data.type === AddressTypes.CUSTOMER ||
                        data.type === AddressTypes.INVOICE) && (
                        <Field
                            label="Jméno"
                            required
                            errorText={validate_result("first_name").error}
                            invalid={validate_result("first_name").is_invalid}
                        >
                            <Input
                                placeholder="Jméno"
                                autoFocus
                                value={data.first_name}
                                onChange={(e) =>
                                    handleAddress({...data, first_name: e.target.value})
                                }
                            />
                        </Field>
                    )}

                    {(data.type === AddressTypes.CUSTOMER ||
                        data.type === AddressTypes.INVOICE) && (
                        <Field label="Příjmení" required>
                            <Input
                                placeholder="Příjmení"
                                value={data.last_name}
                                onChange={(e) =>
                                    handleAddress({...data, last_name: e.target.value})
                                }
                            />
                        </Field>
                    )}
                    {(data.type === AddressTypes.PICKUP ||
                        data.type === AddressTypes.DELIVERY) && (
                        <Field
                            label="Kontaktní osoba"
                            required
                            errorText={validate_result("first_name").error}
                            invalid={validate_result("first_name").is_invalid}
                        >
                            <Input
                                placeholder="Kontaktní osoba"
                                value={data.first_name}
                                onChange={(e) =>
                                    handleAddress({...data, first_name: e.target.value})
                                }
                            />
                        </Field>
                    )}
                    <Field label="Mobil" required invalid={validate_result("phone").is_invalid}
                           errorText={validate_result("phone").error}>
                        <Input
                            mask={
                                data.country
                                    ? data.country === "CZ"
                                        ? "+420 999 999 999"
                                        : "+421 999 999 999"
                                    : "+429 999 999 999"
                            }
                            placeholder="Mobil"
                            value={data.phone}
                            onChange={(e: any) =>
                                handleAddress({...data, phone: e.target.value})
                            }
                        />
                    </Field>
                </Flex>
                <Flex direction={{base: "column", md: "row"}} gap={4}>
                    <Field label="Ulice a ČP" required invalid={validate_result("street").is_invalid}
                           errorText={validate_result("street").error}>
                        <Input
                            placeholder="Ulice a ČP"
                            value={data.street}
                            onChange={(e) =>
                                handleAddress({...data, street: e.target.value})
                            }
                        />
                    </Field>
                    <Field label="Město" required invalid={validate_result("city").is_invalid}
                           errorText={validate_result("city").error}>
                        <Input
                            placeholder="Město"
                            value={data.city}
                            onChange={(e) => handleAddress({...data, city: e.target.value})}
                        />
                    </Field>
                    <Field label="PSČ" w={"40%"} required invalid={validate_result("postal_code").is_invalid}
                           errorText={validate_result("postal_code").error}>
                        <Input
                            w="100"
                            mask="999 99"
                            value={data.postal_code}
                            onChange={(e: any) =>
                                handleAddress({...data, postal_code: e.target.value})
                            }
                        />
                    </Field>
                </Flex>
            </Flex>
        </>
    );
}

export default Address;
