import {HubConnectionState} from "@microsoft/signalr";
import {Core, CoreMessage} from "src/types/core";
import {CameraFile} from "src/types/camera";

export const setEditorState = (
    state: Core,
    action: { payload: { open: boolean, file: CameraFile | null } }
) => {
    state.camera.editor.open = action.payload.open;
    state.camera.editor.file = action.payload.file;
}

export const setCameraResolution = (
    state: Core,
    action: { payload: { height: number; width: number, facingMode: string } }
) => {
    state.camera.resolution = action.payload;
}


export const setCameraConnectParent = (
    state: Core,
    action: { payload: boolean }
) => {
    state.camera.isConnectParent = action.payload;
}

export const setCameraOpen = (
    state: Core,
    action: { payload: { state: boolean, files: Array<CameraFile>, asNoClose?: boolean } }
) => {
    state.camera.open = action.payload.state;
    state.camera.files = action.payload.files;
    state.camera.asNoClose = action.payload.asNoClose ?? false;
}

export const setCameraOpenFileViewer = (
    state: Core,
    action: { payload: boolean }
) => {
    state.camera.openFileViewer = action.payload;
}

export const setCameraOpenDoctypeMenu = (
    state: Core,
    action: { payload: boolean }
) => {
    state.camera.openDoctypeMenu = action.payload;
}

export const setCameraOpenMobile = (
    state: Core,
    action: { payload: boolean }
) => {
    state.camera.openMobile = action.payload;
}

export const setCameraConnectChild = (
    state: Core,
    action: { payload: boolean }
) => {
    state.camera.isConnectChild = action.payload;
}

export const setCameraStandalone = (state: Core) => {
    state.camera.open = true;
    state.camera.asNoClose = true;
};

export const setCameraParentId = (
    state: Core,
    action: { payload: string | null }
) => {
    state.camera.parentId = action.payload;
    state.camera.asChild = true;
    state.camera.open = true;
    state.camera.asNoClose = true;
}

export const setChildCameraFile = (state: Core, action: { payload: string }) => {
    const date = new Date();
    const prefix = state.camera.documentType;
    let file = {
        name: `${prefix}_${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}.png`,
        content: action.payload,
        type: "image/png"
    } as CameraFile;
    state.camera.files.push(file);
}

export const setCameraFile = (
    state: Core,
    action: { payload: CameraFile }
) => {
    if (action.payload.name.indexOf("_") === -1)
        action.payload.name = `${state.camera.documentType}_${action.payload.name}`;

    if (state.camera.files.some(x => x.name === action.payload.name)) {
        //Soubor přepíšeme
        var fileIndex = state.camera.files.findIndex(x => x.name === action.payload.name);
        state.camera.files[fileIndex] = action.payload;
    } else {
        state.camera.files.push(action.payload);
    }
}

export const setCameraFileRemove = (
    state: Core,
    action: { payload: CameraFile }
) => {
    var fileIndex = state.camera.files.findIndex(x => x.name === action.payload.name);
    state.camera.files.splice(fileIndex, 1);
}

export const setEditorOpenDoctypeMenu = (state: Core, action: { payload: boolean }) => {
    state.camera.editor.openDoctypeMenu = action.payload;
};

export const renameCameraFile = (state: Core, action: { payload: { ffile: CameraFile, newName: string } }) => {
    if (state.camera.files.some(x => x.name === action.payload.ffile.name)) {
        const fileIndex = state.camera.files.findIndex(x => x.name === action.payload.ffile.name);
        state.camera.files[fileIndex].name = `${action.payload.newName}_${state.camera.files[fileIndex].name.split("_")[1]}`;
    }
}

export const setAfterSave = (state: Core, action: { payload: boolean }) => {
    state.camera.files = [];
    state.camera.editor.file = null;
    state.camera.editor.open = false;
};

export const setCameraDocumentType = (
    state: Core,
    action: { payload: string }
) => {
    window.localStorage.setItem("scan-doc-type", action.payload)
    state.camera.documentType = action.payload;
}

export const setCameraLoading = (
    state: Core,
    action: { payload: boolean }
) => {
    state.camera.loading = action.payload;
}

export const setCameraShowEffect = (
    state: Core,
    action: { payload: boolean }
) => {
    state.camera.showEffect = action.payload;
}

export const setState = (state: Core, action: { payload: boolean }) => {
    state.loading = action.payload;
    state.loading_text = "";
};

export const setConnection = (
    state: Core,
    action: {
        payload: { state: HubConnectionState; connectionId: string | null; message: string | null };
    }
) => {
    state.signalr.state = action.payload.state === HubConnectionState.Connected;
    state.signalr.connection_id = action.payload.connectionId;
    state.signalr.message = action.payload.message ?? "";
};

export const newMessage = (
    state: Core,
    action: {
        payload: CoreMessage;
    }
) => {
    state.messages.push({
        title: action.payload.title ?? "RTS Notifikace",
        message: action.payload.message,
        serverity: action.payload.serverity,
        duration: action.payload.duration,
    });
};

export const consumeMessage = (
    state: Core,
    action: { payload: CoreMessage }
) => {
    state.messages.splice(
        state.messages.findIndex((x) => x === action.payload),
        1
    );
};

export const setStateWithText = (
    state: Core,
    action: { payload: { value: boolean; text: string } }
) => {
    state.loading = action.payload.value;
    state.loading_text = action.payload.value ? action.payload.text : "";
};

export const setIframe = (state: Core, action: { payload: boolean }) => {
    state.iframe = action.payload;
};


