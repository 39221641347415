import type {User, UserDetail} from "src/types/users";
import {__AuthToken} from "src/config";


export const fakeLogin = (state: User, action: { payload: UserDetail }) => {
    var payload: UserDetail[] = [action.payload];
    LogIn(state, {payload: payload});
};

export const LogIn = (state: User, action: { payload: UserDetail[] }) => {
    if (action.payload !== null && action.payload?.length === 1) {
        state.logged = action.payload[0];
        if (state.logged?.access_token !== undefined)
            window.localStorage.setItem("token", state.logged?.access_token);
    }
        

    state.isAuth = state.logged?.access_token !== undefined;
}
export const Auth = (state: User, action: { payload: UserDetail[] }) => {
    console.log(action.payload);
    if (action.payload !== null && action.payload?.length === 1) {
        LogIn(state, action);
    } else {
        //LogOut(state);
    }
}
export const LogOut = (state: User) => {
    state.isAuth = false;
    state.logged = null;
    localStorage.removeItem("token");
    return state;
};
