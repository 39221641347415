import PanelContainer from "../components/MainPanel/panelContainer";
import { Route, Routes } from "react-router-dom";
import CreateServiceRequest from "../views/serviceRequest";
import theme from "../theme/theme";
import { ChakraProvider } from "@chakra-ui/react";
import CameraConnect from "src/views/camera.connect";

export const Iframe = () => {
  return (
    <ChakraProvider value={theme}>
        <PanelContainer>
          <Routes>
            <Route path="serviceRequest" element={<CreateServiceRequest />} />
            <Route path="mobile/scan" element={<CameraConnect />} />
          </Routes>
        </PanelContainer>
    </ChakraProvider>
  );
};
