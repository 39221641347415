import * as signalR from "src/lib/signalR";
import {Result} from "src/types/signalR";
import {CoreMessage} from "src/types/core";
import {EnhancersFrom} from "src/enums";

async function ErrorMessages<T>(action: any, store: any, response: Result<T>) {
    if (!response.is_success)
        store.dispatch({
            type: "core/newMessage", result: null, payload: {
                title: action.type,
                message: response.message,
                serverity: response.is_success ? "success" : "error",
                duration: 5000
            } as CoreMessage
        })
}

async function signalRHelper<T>(action: any, store: any) {
    return signalR
        .sendMessageWithTimeout(action.messageType, action.payload)
        .then((response: Result<T>) => {
            ErrorMessages(action, store, response);
            return response.response;
        }).catch((err: any) => {
            ErrorMessages(action, store, {
                is_success: false,
                message: err.message,
                response: null,
                statusCode: 500,
                exception: null
            });
            return null
        });
}


export function signalRMiddleware(store: any) {
    return (next: any) => async (action: any) => {
        if (action) {
            if ((action?.from ?? "") === EnhancersFrom.signalR) {
                const result = await signalRHelper<Result<any>>(action, store).then((response) => response);
                return next({
                    ...action,
                    payload: result
                });
            } else
                return next(action);
        }
    }
}