import Enhancer, {Generate} from "src/redux/enhancer";
import {EnhancersFrom, signalR} from "src/enums";
import {CameraFile} from "src/types/camera";

export const signalRActions = (storeName: string) => {
    return {
        ChangeDocType: (docType: string, groupName: string) =>
            Generate(storeName, {
                groupName,
                docType
            }, "setCameraDocumentTypeBefore", EnhancersFrom.signalR, signalR.core.changeDocType),
    }
}

export const extraActions = (storeName: string) => {
    return {
        GetTempFile: (sha256: string) =>
            Generate(storeName, {sha256}, "setChildCameraFile", EnhancersFrom.fetch, undefined, "/api/SandBox/GetTempFile", "POST"),
        SaveToDoc: (files: Array<CameraFile>, DocNo: string) =>
            Generate(storeName, {
                files: files,
                id: DocNo
            }, "setAfterSave", EnhancersFrom.fetch, undefined, "/api/Info21/SavePhotoFile", "POST"),
    }
};

export function ActionsEnhancer<T>(action: T, storeName: string) {
    return Enhancer(action, signalRActions(storeName), {}, extraActions(storeName));
}