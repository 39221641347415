import {createSlice} from "@reduxjs/toolkit";
import * as reducers from "./core.reducers";
import {initialState} from "./core.init";
import {ActionsEnhancer} from "./core.actions";

const name = "core";

const CoreSlice = createSlice({
    name,
    initialState,
    reducers,
});

export const CoreReducer = CoreSlice.reducer;
export const CoreActions = ActionsEnhancer(CoreSlice.actions, name);
