import {
    createSystem,
    defineConfig,
    defaultConfig,
    mergeConfigs,
} from "@chakra-ui/react";
import {cardSlotRecipe} from "./components/card.recipe";
import {buttonRecipe} from "./components/button.recipe";
import {inputRecipe} from "./components/input.recipe";
import {badgeRecipe} from "./components/badge.recipe";
import {selectSlotRecipe} from "./components/select.recipe";
import {textareaRecipe} from "./components/textarea.recipe";
import {numberInputSlotRecipe} from "./components/number-input.recipe";
import {alertSlotRecipe} from "./components/alert.recipe";
import {radioCardSlotRecipe} from "./components/radio-card.recipe";
import {menuSlotRecipe} from "./components/menu.recipe";
import {dialogSlotRecipe} from "./components/dialog.recipe";
import {datePickerSlotRecipe} from "./components/datePicker.recipe";

const config = defineConfig({
    cssVarsPrefix: "rts",
    globalCss: {
        html: {
            fontFamily: "'TriviaSeznam', sans-serif",
            _light: {
                background: "#fff",
            },
            _dark: {
                background: "#1a1a1a",
            }
        },
        body: {
            fontFamily: "'TriviaSeznam', sans-serif",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",

        },
    },
    theme: {
        recipes: {
            button: buttonRecipe,
            badge: badgeRecipe,
            input: inputRecipe,
            textarea: textareaRecipe,
        },
        slotRecipes: {
            card: cardSlotRecipe,
            select: selectSlotRecipe,
            numberInput: numberInputSlotRecipe,
            alert: alertSlotRecipe,
            radioCard: radioCardSlotRecipe,
            menu: menuSlotRecipe,
            dialog: dialogSlotRecipe,
            datePicker: datePickerSlotRecipe,
        },
    },
});

const ThemeConfig = () => {
    const merged = mergeConfigs(defaultConfig, config);
    const system = createSystem(merged);
    system.token("gold.50", "#FAFAD2");
    return system;
};

export default ThemeConfig();
