import {Button, Card, Flex, Lu, Text } from "src/components";

export default function SoDetailItems() {
  return (
    <Flex w={"100%"} direction={"column"} gap={2}>
      <Button variant={"subtle"} aria-label="Přidat položku" gap={2} alignContent={"center"}>
        <Lu.LuPlus /> Přidat položku
      </Button>
      <Card.Root w={"100%"}>
        <Card.Body>
          <Flex gap={2} direction={"row"} justifyContent={"space-between"}>
            <Flex direction={"column"}>
              <Text fontSize={"sm"} fontWeight={"bold"}>
                Artikl č.: 123456
              </Text>
              <Text fontSize={"xs"}>ASSY COVER BACK MIDNIGHT BLACK</Text>
            </Flex>

            {/* <INumberInput
              value={0}
              onSave={() => {}}
              isLoading={false}
              idx={0}
              isDisabled={false}
            /> */}
          </Flex>
        </Card.Body>
      </Card.Root>
      <Card.Root w={"100%"}>
        <Card.Body>
          <Flex
            gap={2}
            direction={"row"}
            justifyContent={"space-between"}
            align={"center"}
          >
            <Flex direction={"column"}>
              <Text fontSize={"sm"} fontWeight={"bold"}>
                Artikl č.: 7ad7as7
              </Text>
              <Text fontSize={"xs"}>
                ASSY 7ad74sa 8d4as8 COVER BACK MIDNIGHT BLACK
              </Text>
              <Flex gap={2} direction={"row"} align={"center"}>
                <Text fontSize={"sm"} mt={1}>Sklad:</Text>
                {/* <Editable
                  mt={1}
                  defaultValue="3001"
                  size={"sm"}
                  fontSize={"sm"}
                >
                  <EditablePreview />
                  <EditableInput />
                </Editable> */}
                <Text ms={6} fontSize={"sm"} mt={1}>Počet kusů:</Text>
                {/* <Editable
                  mt={1}
                  defaultValue="1"
                  size={"sm"}
                  fontSize={"sm"}
                >
                  <EditablePreview />
                  <EditableInput />
                </Editable> */}
              </Flex>
            </Flex>
            <Flex gap={2} direction={"row"} align={"center"}>
              <Button
                size={"sm"}
                aria-label="Historie"
              />
              <Button
                size={"sm"}
                aria-label="Odebrat"
                bg={"red.500"}
              />
            </Flex>
          </Flex>
        </Card.Body>
      </Card.Root>
      <Card.Root w={"100%"}>
        <Card.Body>
          <Flex gap={2} direction={"row"} justifyContent={"space-between"}>
            <Flex direction={"column"}>
              <Text fontSize={"sm"} fontWeight={"bold"}>
                Artikl č.: 484d8s48
              </Text>
              <Text fontSize={"xs"}>
                ASSY COVER 94sa84d 484as8 BACK MIDNIGHT BLACK
              </Text>
            </Flex>

            {/* <INumberInput
              value={0}
              onSave={() => {}}
              isLoading={false}
              idx={0}
              isDisabled={false}
            /> */}
          </Flex>
        </Card.Body>
      </Card.Root>
    </Flex>
  );
}
