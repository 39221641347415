export const hover = {
  cursor: "pointer",
  transition: "all 0.2s",
  "&:hover": {
    _dark: {
      bg: "gray.800",
    },
    _light: {
      bg: "gray.200",
    },
    boxShadow: "md",
    transform: "scale(1.01)",
  },
};

