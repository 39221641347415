import { slotRecipe, alertAnatomy } from "../recipe.anatomy";

export const alertSlotRecipe = slotRecipe({
  className: "chakra-alert",
  slots: alertAnatomy.keys(),
  base: {
    root: {
      borderRadius: "15px",
    }
  },
});

