import {Fa, Lu} from "src/components";

import Core from "src/rts.core";
import ServiceRequest from "./views/serviceRequest/index";
import SoPreview from "./views/serviceOrder/so.preview";
import SoPriceProposal from "./views/serviceOrder/components/so.detail.priceproposal";
import LPPreview from "./views/logisticsPackages/lp.preview";
import CameraConnect from "src/views/camera.connect";

export const RtsRoutes = {
    Core,
    ServiceRequest,
    SoPreview,
    SoPriceProposal,
    LPPreview,
}


const dashRoutes: Array<any> = [
    {
        path: "/",
        name: "Servisní zakázky",
        icon: <Fa.FaHome color="inherit"/>,
        component: <SoPreview/>,
        layout: "",
        sidebar: false,
    },
    {
        path: "/priceproposal/:id",
        name: "Vytvořit cenovou nabídku",
        icon: <Fa.FaHome color="inherit"/>,
        component: <SoPriceProposal/>,
        layout: "",
        sidebar: false,
    },
    {
        path: "/serviceorders",
        name: "Servisní zakázky",
        icon: <Fa.FaFileContract color="inherit"/>,
        component: <SoPreview/>,
        layout: "",
        sidebar: true,
    },
    {
        path: "/create/service/request",
        name: "Zásilky",
        icon: <Fa.FaFileContract color="inherit"/>,
        component: <ServiceRequest/>,
        layout: "",
        sidebar: true,
    },
    {
        path: "/packages",
        name: "Balíky",
        icon: <Fa.FaBoxOpen color="inherit"/>,
        component: <LPPreview/>,
        layout: "",
        sidebar: true,
    },
    {
        path: "/camera",
        name: "Kamera",
        icon: <Lu.LuCamera color="inherit"/>,
        component: <CameraConnect/>,
        layout: "",
        sidebar: false,
    },
    {
        path: "/serviceorders/price-proposal",
        name: "Cenová nabídka",
        icon: <Lu.LuCamera color="inherit"/>,
        component: <SoPriceProposal/>,
        layout: "",
        sidebar: false,
    }
];

export default dashRoutes;
