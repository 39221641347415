import { menuAnatomy, slotRecipe } from "../recipe.anatomy";

export const menuSlotRecipe = slotRecipe({
  className: "chakra-menu",
  slots: menuAnatomy.keys(),
  base: {
    content: {
      borderRadius: "15px",
    },
    item: {
      borderRadius: "15px",
    },
  },
});

