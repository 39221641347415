import {useEffect} from "src/items";
import {Image, Flex, Alert} from "src/components";
import HeadIframePage from "../head.iframe.page";
import Interface from "./interfaces/interface.components";
import undraw from "../../assets/img/undraw_devices_re_dxae.svg";
import PosamSk from "./extenders/posam.sk";
import {useRedux} from "src/redux/provider";
import {Countries} from "src/enums";
import {__oficialWeb} from "src/config";

export default function CreateServiceRequest() {
    const {useStore, dispatch, dispatchAsync, actions} = useRedux();
    const activeStep = useStore.service_request.activeStep;
    const verify = localStorage.getItem("token") ?? "";
    useEffect(() => {
        dispatch(actions.shared.signalR.retrieveFirstPickupDate(Countries.Czech));
        if (verify != "" && useStore.service_request.activeStep === -1)
            dispatchAsync(actions.shared.signalR.getAuthorize()).then(() => {
            });
    }, []);

    useEffect(() => {
        if (useStore.shared.is_authorized && useStore.service_request.activeStep === -1) {
            dispatch(actions.service_request.nextStep());
            dispatch(actions.service_request.setEmail(useStore.shared.e_mail));
        }
    }, [useStore.shared.is_authorized]);

    useEffect(() => {
    }, [useStore.service_request.invoice_address]);

    return (
        <HeadIframePage
            title="Vytvořit nový požadavek na opravu a svoz zařízení"
            return_link={__oficialWeb}
        >
            <Image
                display={{base: "none", md: "block"}}
                src={undraw}
                bg={"transparent"}
                position={"fixed"}
                bottom={5}
                right={0}
                width={250}
            />

            {/* Zobrazujeme stepper a ovládací tlačítka až když se zadává nový požadavek */}
            {activeStep > -1 && !useStore.service_request.is_send && (
                <>
                    <Interface.Stepper/>
                    <Interface.ControllerForm/>
                </>
            )}
            {useStore.service_request.is_send && <Interface.Finish/>}
            {!useStore.service_request.is_send && (
                <>
                    <Interface.AddressDialogIco/>
                    <Interface.AddressDialog/>
                    <Interface.DeviceEditor/>
                    <Flex direction={"column"} mt={6} gap={4}>
                        {useStore.service_request.any_error !== "" && (
                            <Alert status="error">
                                {useStore.service_request.any_error}
                            </Alert>
                        )}
                        {activeStep === -1 && <Interface.Verification/>}
                        {activeStep === 0 && <Interface.Card/>}
                        {activeStep === 0 && <PosamSk/>}
                        {activeStep === 1 && <Interface.Device/>}
                        {activeStep === 2 && <Interface.Transport/>}
                        {activeStep === 3 && <Interface.Summary/>}
                        {activeStep > -1 && <Interface.ControllerForm/>}
                    </Flex>
                </>
            )}
        </HeadIframePage>
    );
}
