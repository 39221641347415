import Address from "./address-form";
import {CustomerAddress} from "src/types/service_request";
import {AddressTypes} from "src/enums";
import React, {useState} from "react";

import {
    Button,
    DialogBody,
    DialogCloseTrigger,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogRoot,
    DialogTitle
} from "src/components";
import {useRedux} from "src/redux/provider";

export default function AddressDialog() {
    const {useStore, dispatch, actions} = useRedux();
    const [isValid, setIsValid] = useState(false);
    const data = useStore.service_request;
    const props = useStore.service_request.address_dialog;
    const onClose = () =>
        dispatch(
            actions.service_request.setAddressDialog({
                open: false,
                isPickup: false,
            })
        );
    const new_address = {
        type: props.isPickup
            ? AddressTypes.PICKUP
            : props.isInvoice ?? false
                ? AddressTypes.INVOICE
                : AddressTypes.DELIVERY,
        first_name: "",
        last_name: "",
        phone: "",
        street: "",
        city: "",
        postal_code: "",
        country: "",
        company_id: "",
    } as CustomerAddress;
    const __handleSave = () => onClose();
    return (
        <DialogRoot size={"xl"} open={props.open} onEscapeKeyDown={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Nová Adresa</DialogTitle>
                    <DialogCloseTrigger onClick={onClose}/>
                </DialogHeader>
                <DialogBody>
                    <Address
                        isValid={setIsValid}
                        data={
                            props.isPickup
                                ? data.pickup_address ?? new_address
                                : props.isInvoice ?? false
                                    ? data.invoice_address ?? new_address
                                    : data.delivery_address ?? new_address
                        }
                    />
                </DialogBody>
                <DialogFooter>
                    <Button variant={"subtle"} colorPalette={"green"} onClick={__handleSave} disabled={!isValid}>
                        Uložit
                    </Button>
                </DialogFooter>
            </DialogContent>
        </DialogRoot>
    );
}
