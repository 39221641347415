import {
    toaster,
    Toaster,
    DialogRoot,
    DialogContent,
    DialogBody,
    Flex,
    Spinner,
    Text,
    useLocation,
    useNavigate,
    useEffect,
    Layout,
    Camera,
} from "src/items";
import * as signalR from "src/lib/signalR";
import {useRedux} from "src/redux/provider";
import LpItemDetail from "src/views/logisticsPackages/lp.itemdetail";
import React from "react";

const Core = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {useStore, dispatch, actions} = useRedux();
    
    
    
    useEffect(() => {
        if (useStore.core.signalr.state) {
            if (useStore.shared.countries?.length === 0)
                dispatch(actions.shared.signalR.retrieveVendors());

            if (useStore.shared.vendors?.length === 0)
                dispatch(actions.shared.signalR.retrieveCountries());
        }

        signalR.onChangeConnection((state, stateMsg, connectionId) => dispatch(actions.core.setConnection({
            connectionId: connectionId,
            state: signalR.hubConnect.state,
            message: stateMsg
        })));

        var __token = localStorage.getItem("token") ?? "";

        if (__token !== "" && useStore.users.logged === null)
            dispatch(actions.users.signalR.Auth());

    }, [useStore.core.signalr.state]);

    useEffect(() => {
        if (useStore.core.messages.length > 0) {
            let item = useStore.core.messages[0];
            toaster.create({
                title: item.title,
                description: item.message,
                type: item.serverity,
                duration: item.duration,
            });
            dispatch(actions.core.consumeMessage(item));
        }
    }, [useStore.core.messages]);

    useEffect(() => {
        if (location.pathname === "/serviceRequest" || location.pathname === "/mobile/scan")
            dispatch(actions.core.setIframe(true));
        else dispatch(actions.core.setIframe(false));
    }, [location.pathname]);

    useEffect(() => {
        if (useStore.users.isAuth)
            if (location.pathname.indexOf("auth2") > -1)
                navigate(localStorage.getItem("lastPath")!);
    }, [useStore.users.isAuth]);
    const handleConnecting = () => {
        return (
            <DialogRoot open={!useStore.core.signalr.state} placement={"center"} size={"xl"}>
                <DialogContent>
                    <DialogBody>
                        <Flex direction={"column"} p={"100px"} gap={4} align={"center"}>
                            <Spinner/>
                            <Text>{useStore.core.signalr.message}</Text>
                        </Flex>
                    </DialogBody>
                </DialogContent>
            </DialogRoot>
        );
    };

    return (
        <>
            {!useStore.core.signalr.state && handleConnecting()}
            {useStore.core.signalr.state &&
                <>
                    <Toaster/>
                    {useStore.core.iframe && <Layout.Iframe/>}
                    {!useStore.core.iframe && <Layout.MainLayout/>}
                    {useStore.core.camera.open && <Camera/>}
                    {useStore.logistics_packages.detail.state && <LpItemDetail/>}

                </>
            }
        </>
    );
};

export default Core;
