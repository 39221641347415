import {useEffect, useState} from "react";
import {device_w, device_oow} from "../interfaces/interface.validation";
import React from "react";
import {useDisclosure} from "src/core";
import {ValueChangeDetails} from "@zag-js/number-input";
import {FileAcceptDetails} from "@zag-js/file-upload";
import {CheckedChangeDetails} from "@zag-js/checkbox";
import {
    Alert,
    Button,
    Card,
    DialogBody,
    DialogCloseTrigger,
    DialogContent,
    DialogHeader,
    Flex,
    DialogRoot,
    DrawerBackdrop,
    DrawerBody,
    DrawerCloseTrigger,
    DrawerContent,
    DrawerHeader,
    DrawerRoot,
    Field,
    Input,
    RadioCardItem,
    RadioCardRoot,
    FileUploadRoot,
    FileUploadDropzone,
    NumberInputRoot,
    NumberInputField,
    FileUploadList,
    Textarea,
    Text, Checkbox, DrawerFooter, Fa, DatePicker, Locale
} from "src/components";
import {useRedux} from "src/redux/provider";
import {IFile} from "src/types/shared";


function getUniqueItems(root: IFile[], pusher: File[], compareFn: (a: string | undefined, b: string | undefined) => boolean): File[] {
    return pusher.filter(pusherItem => !root.some(rootItem => compareFn(rootItem.name, pusherItem.name)));
}

function Device() {
    const {useStore, dispatch, actions} = useRedux();
    const data = useStore.service_request.devices;


    const dispatcher = actions.service_request;
    return (
        <>
            <Button
                variant={"subtle"}
                onClick={() => dispatch(actions.service_request.addDevice())}
            >
                Přidat zařízení
            </Button>
            <Alert mt={1} mb={2} status="warning" variant="subtle" fontSize="sm">
                Přidejte zařízení pro které žádáte o servis. V případě, že máte více
                zařízení, můžete zadat více zařízení. Každé zařízení bude mít své číslo
                reklamace. Pokud žádáte o svoz, tak je nutné zadat jen tolik zařízení,
                aby hodnota součtu hodnot zadaných zařízení nepřekročila 50 000 Kč s
                DPH. Pokud součet hodnot jednotlivých zařízení přesáhne hodnotu 50 000
                Kč s DPH, bude, v případě poškození / ztráty přepravního balíku
                přepravní společností, nárok na náhradu škody uplatněn v maximální výši
                50 000 Kč s DPH.
            </Alert>
            {data.map((x, idx) => (
                <DeviceCard idx={idx}/>
            ))}
        </>
    );
}

export const DeviceEditor = () => {
    const {useStore, dispatch, actions} = useRedux();
    const drawer = useStore.service_request.device_edit;
    const data = useStore.service_request.devices[drawer.idx];
    const [error_validate, setValidateError] = useState<any[] | null>(null);
    const {open, onOpen, onClose} = useDisclosure();
    const allowed_vendors = [1, 2, 4, 6, 8, 15, 53, 55, 88, 90];

    const warranty_dates = () => {
        let date = new Date();
        let datemax = new Date();
        let min = date.setDate(date.getDate() - 820);
        let max = datemax.setDate(datemax.getDate());
        return {min, max};
    };

    const is_invalid = (type?: string) => {
        if (type === undefined) {
            return error_validate !== null;

        }

        let error = error_validate?.filter((x) => x.path[0] === type);
        return !(error === undefined || error?.length === 0);

    };

    const handleChange = (e: any) => {
        dispatch(actions.service_request.saveDevice(e));
    };

    const invalid_message = (type: string) => {
        let error = error_validate?.filter((x) => x.path[0] === type);
        return error?.map((x) => x.message).join(", ");
    };

    const validate = () => {
        try {
            if (data.is_warranty) device_w.parse(data);
            else device_oow.parse(data);
            setValidateError(null);
            return true;
        } catch (e: any) {
            setValidateError(JSON.parse(e));
            return false;
        }
    };

    useEffect(() => {
        if (drawer.is_open) validate();
    }, [data]);

    const validate_save = () => {
        const validate_result = validate();
        if (validate_result) {
            dispatch(actions.service_request.saveDevice({is_saved: true}));
            dispatch(actions.service_request.closeEditor());
        }
    };

    const years = Array.from({length: 4}, (_, i) => new Date().getFullYear() - 3 + i);
    const months = Locale();

    return drawer.is_open ? (
        <>
            <DialogRoot
                onInteractOutside={onClose}
                onEscapeKeyDown={onClose}
                open={open}
                closeOnEscape
                closeOnInteractOutside
            >
                <DialogContent>
                    <DialogHeader>
                        Vyberte výrobce <DialogCloseTrigger onClick={onClose}/>
                    </DialogHeader>
                    <DialogBody overflowY={"visible"}>
                        <Input placeholder="Vyhledat výrobce"/>
                        <RadioCardRoot
                            mt={4}
                            gap={2}
                            onChangeCapture={(e: any) => {
                                handleChange({vendor: e.target.value});
                                onClose();
                            }}
                        >
                            {useStore.shared.vendors
                                .filter((x) => allowed_vendors.includes(Number(x.value)))
                                .map((x) => (
                                    <RadioCardItem
                                        label={x.label}
                                        key={x.value}
                                        value={x.value}
                                    />
                                ))}
                        </RadioCardRoot>
                    </DialogBody>
                </DialogContent>
            </DialogRoot>
            <DrawerRoot
                open={drawer.is_open}
                size={"lg"}
                closeOnEscape={false}
                closeOnInteractOutside={false}
            >
                <DrawerBackdrop/>
                <DrawerContent>
                    <DrawerHeader>
                        <Field
                            label="Výrobce"
                            required
                            invalid={is_invalid("vendor")}
                            errorText={invalid_message("vendor")}
                        >
                            <Button w={"100%"} variant={"subtle"} onClick={onOpen}>
                                {data.vendor === ""
                                    ? "Vybrat výrobce"
                                    : `${
                                        useStore.shared.vendors.filter(
                                            (x) => x.value === data.vendor
                                        )[0]?.label
                                    }`}
                            </Button>
                        </Field>
                    </DrawerHeader>
                    <DrawerCloseTrigger
                        onClick={() => {
                            dispatch(actions.service_request.closeEditor());
                            if (!data.is_saved)
                                dispatch(
                                    actions.service_request.removeDevice(drawer.idx)
                                );
                        }}
                    />
                    <DrawerBody>
                        <Flex direction={"column"} gap={4}>
                            <Flex direction={{base: "column", md: "row"}} gap={4}>
                                <Field label="Vaše číslo reklamace">
                                    <Input
                                        placeholder="Vaše číslo reklamace"
                                        value={data.num_at_card}
                                        onChange={(e) =>
                                            handleChange({num_at_card: e.target.value})
                                        }
                                    />
                                </Field>
                                <Field
                                    label="IMEI / SN"
                                    required
                                    invalid={is_invalid("imei")}
                                    errorText={invalid_message("imei")}
                                >
                                    <Input
                                        placeholder="IMEI / SN"
                                        value={data.imei ?? data.serial_number}
                                        onChange={(e) =>
                                            handleChange({
                                                imei: e.target.value,
                                                serial_number: e.target.value,
                                            })
                                        }
                                    />
                                </Field>
                                <Field
                                    label="Typ"
                                    required
                                    invalid={is_invalid("type")}
                                    errorText={invalid_message("type")}
                                >
                                    <Input
                                        placeholder="Typ"
                                        value={data.type}
                                        onChange={(e) => handleChange({type: e.target.value})}
                                    />
                                </Field>
                            </Flex>
                            <Flex>
                                <Field label="Typ opravy" required>
                                    <RadioCardRoot
                                        mt={4}
                                        gap={2}
                                        defaultValue={data.is_warranty ? "1" : "2"}
                                        onChangeCapture={(e: any) => {
                                            handleChange({
                                                is_warranty: e.target.value === "1",
                                            });
                                            onClose();
                                        }}
                                    >
                                        <Flex direction={{base: "column", md: "row"}} gap={2}>
                                            <RadioCardItem
                                                w={"100%"}
                                                label={"Záruční"}
                                                description="Oprava bude zdarma na základě záručního listu nebo dokladu o prodeji"
                                                key={"IW"}
                                                value={"1"}
                                            />
                                            <RadioCardItem
                                                w={"100%"}
                                                label={"Pozáruční"}
                                                description="Oprava bude zpoplatněna na základě cenové nabídky nebo předpokládané ceny opravy"
                                                key={"OOW"}
                                                value={"2"}
                                            />
                                        </Flex>
                                    </RadioCardRoot>
                                </Field>
                            </Flex>
                            <Field label="Reklamační protokol">
                                <FileUploadRoot
                                    maxFiles={2}
                                    maxFileSize={5 * 1024 * 1024}
                                    onFileAccept={(e: FileAcceptDetails) =>
                                        dispatch(actions.service_request.extra
                                            .storeReceiptTempFile(e.files.filter((x) => data.receipt_files.findIndex((y) => y.name === x.name) === -1)))
                                    }
                                    capture="environment"
                                    title=""
                                >
                                    <FileUploadDropzone
                                        w={"100%"}
                                        label="Pokud máte reklamační protokol, přiložte jej prosím....."
                                        description=".png, .jpg, .pdf do velikosti 5MB"
                                    />
                                    <FileUploadList showSize clearable files={data.receipt_files}/>
                                </FileUploadRoot>
                            </Field>
                            {!data.is_warranty && (
                                <Field
                                    label="Předpokládaná cena opravy"
                                    required
                                    invalid={is_invalid("estimated_repair_cost")}
                                    errorText={invalid_message("estimated_repair_cost")}
                                >
                                    <Alert
                                        mt={1}
                                        mb={2}
                                        status="warning"
                                        variant="subtle"
                                        fontSize="sm"
                                    >
                                        Minimální předpokládaná cena opravy je 500 Kč včetně DPH.
                                        Dojde-li k navýšení udané předpokládané ceny více než o 10%,
                                        bude vystavena cenová nabídka. V opačném případě bude
                                        zařízení automaticky opraveno bez vystavení cenové nabídky.
                                        V případě, že se s cenovou nabídkou nebudete souhlasit, bude
                                        automaticky účtována částka za vypracování cenové nabídky a
                                        také dopravné v celkové výši maximálně 550 Kč s DPH.
                                    </Alert>
                                    <NumberInputRoot
                                        defaultValue={(
                                            data.estimated_repair_cost ?? 550.0
                                        ).toString()}
                                        min={550.0}
                                        step={20.0}
                                        formatOptions={{
                                            style: "currency",
                                            currency: "CZK",
                                            minimumFractionDigits: 2,
                                        }}
                                        value={(data.estimated_repair_cost ?? 550.0).toString()}
                                        onValueChange={(e: ValueChangeDetails) =>
                                            handleChange({estimated_repair_cost: e.valueAsNumber})
                                        }
                                    >
                                        <NumberInputField/>
                                    </NumberInputRoot>
                                </Field>
                            )}
                            {data.is_warranty && (
                                <Flex direction={"column"} gap={4}>
                                    <Field
                                        label="Datum prodeje"
                                        required
                                        errorText={invalid_message("purchase_date")}
                                        invalid={is_invalid("purchase_date")}
                                    >
                                        <DatePicker
                                            selected={data?.purchase_date ? new Date(data?.purchase_date) : undefined}
                                            minDate={new Date(warranty_dates().min)}
                                            maxDate={new Date(warranty_dates().max)}
                                            onChange={(date) => handleChange({purchase_date: date?.toISOString().split("T")[0]})}
                                            inline
                                            showMonthYearDropdown
                                            todayButton={"Dnes"}
                                        />
                                    </Field>
                                    <Field
                                        label="Doklad o prodeji"
                                        required
                                        invalid={is_invalid("warranty_files")}
                                        errorText={invalid_message("warranty_files")}
                                    >
                                        <FileUploadRoot
                                            maxFiles={2}
                                            maxFileSize={5 * 1024 * 1024}
                                            onFileAccept={(e: FileAcceptDetails) =>
                                                dispatch(actions.service_request.extra
                                                    .storeWarrantyTempFile(e.files.filter((x) => data.warranty_files.findIndex((y) => y.name === x.name) === -1)))
                                            }
                                            title=""
                                        >
                                            <FileUploadDropzone
                                                w={"100%"}
                                                label="Přiložte prosím doklad o prodeji....."
                                                description=".png, .jpg, .pdf do velikosti 5MB"
                                            />
                                            <FileUploadList showSize clearable files={data.warranty_files}/>
                                        </FileUploadRoot>
                                    </Field>
                                </Flex>
                            )}
                            <Field
                                label="Popis stavu zařízení"
                                required
                                invalid={is_invalid("damage")}
                                errorText={invalid_message("damage")}
                            >
                                <Textarea
                                    rows={4}
                                    value={data.damage}
                                    onChange={(e) => handleChange({damage: e.target.value})}
                                    tabIndex={22}
                                    placeholder="Textový popis stavu zařízení... (Vizuální stav Vašeho zařízení popište co nejpřesněji. Vyhneme se pak případným neshodám a s tím spojenou prodlouženou dobu opravu.)"
                                />
                            </Field>
                            <Field label="Fotodokumentace zařízení">
                                <FileUploadRoot
                                    maxFiles={5}
                                    maxFileSize={5 * 1024 * 1024}
                                    onFileAccept={(e: FileAcceptDetails) =>
                                        dispatch(actions.service_request.extra
                                            .storeDamageTempFile(e.files.filter((x) => data.damage_files.findIndex((y) => y.name === x.name) === -1)))
                                    }
                                    title=""
                                >
                                    <FileUploadDropzone
                                        w={"100%"}
                                        label="Je-li to možné, přiložte prosím fotodokumentaci zařízení....."
                                        description=".png, .jpg, .pdf do velikosti 5MB"
                                    />
                                    <FileUploadList showSize clearable files={data.damage_files}/>
                                </FileUploadRoot>
                            </Field>
                            <Field
                                label="Popis závady"
                                required
                                invalid={is_invalid("defect")}
                                errorText={invalid_message("defect")}
                            >
                                <Textarea
                                    rows={4}
                                    value={data.defect}
                                    onChange={(e) => handleChange({defect: e.target.value})}
                                    tabIndex={22}
                                    placeholder="Textový popis závady na zařízení... (Pokuste se co nejpřesněji popsat závadu. Ve většině případech se tím urychlý oprava zařízení.)"
                                />
                            </Field>
                            <Flex direction={"row"} align="center" gap={4}>
                                <Checkbox
                                    checked={data.is_backup}
                                    onCheckedChange={(e: CheckedChangeDetails) =>
                                        handleChange({is_backup: !!e.checked})
                                    }
                                />
                                <Text mt={1} fontSize={"xs"}>
                                    Požaduji zálohovat data v zařízení{" "}
                                    <Text color="gray.500">
                                        Doplňková služba zpoplatněna částkou 500 Kč s DPH
                                    </Text>
                                </Text>
                            </Flex>
                            {data.is_backup && (
                                <>
                                    <Alert
                                        mt={1}
                                        mb={2}
                                        status="warning"
                                        variant="subtle"
                                        fontSize="sm"
                                    >
                                        Doplňková služba záloha dat je zpoplatněna částkou 500 Kč s
                                        DPH. Záloha dat je možná pouze u zařízení, která jsou
                                        funkční a je možné je zapnout. U zařízení, která nelze
                                        zapnout, je záloha dat možná pouze po schválení cenové
                                        nabídky (I když se snažíme zachránit Vaše data, tak nemůžeme
                                        na 100% zaručit, že se to povede). Záloha probíhá ze
                                        zařízení do zařízení. Zálohovaná data neukládáme na externí
                                        disky, které si zákazník sám donese! Zálohované data držíme
                                        pouze 14 dní od doby zálohy dat. Po 14ti dnech se zálohy
                                        nenávratně smažou z našich serverů.
                                    </Alert>
                                    <Flex direction={"row"} align="center" gap={4} px={6}>
                                        <Checkbox
                                            checked={data.is_failed_backup_return}
                                            onCheckedChange={(e: CheckedChangeDetails) =>
                                                handleChange({
                                                    is_failed_backup_return: !!e.checked,
                                                })
                                            }
                                        />
                                        <Text mt={1} fontSize={"xs"}>
                                            Pokud záloha nepůjde provést, požaduji zařízení vrátit
                                            zpět bez opravy a zaplatím částku za dopravné a
                                            manipulační poplatek ve výši maximálně 500kč s DPH.
                                        </Text>
                                    </Flex>
                                </>
                            )}
                        </Flex>
                    </DrawerBody>

                    <DrawerFooter justifyContent={"space-between"}>
                        <Text fontSize="sm" color="red.500">
                            {is_invalid() ? "Nebyly doplněny všechny požadované pole" : ""}
                        </Text>
                        <Button
                            variant={"subtle"}
                            colorPalette={"green"}
                            disabled={is_invalid()}
                            onClick={() => validate_save()}
                        >
                            Zavřít a uložit
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </DrawerRoot>
        </>
    ) : (
        <></>
    );
};

export const DeviceCard = ({idx}: { idx: number }) => {
    const {useStore, dispatch, actions} = useRedux();
    const data = useStore.service_request.devices[idx];
    const vendors = useStore.service_request.vendors;
    //const containerRef = React.useRef<HTMLDivElement>(null);
    return (
        <>
            <Card.Root>
                <Card.Header>
                    <Flex direction={"row"} justify={"space-between"}>
                        <Flex direction={"row"} gap={2} align={"center"}>
                            <Fa.FaMobileAlt/>
                            <Text fontSize="md" fontWeight="bold">
                                {vendors.filter((x) => x.value === data.vendor)[0]?.label}
                            </Text>
                        </Flex>
                        <Flex direction={"row"} gap={2} align={"center"}>
                            <Button
                                size="xs"
                                variant={"subtle"}
                                onClick={() =>
                                    dispatch(actions.service_request.removeDevice(idx))
                                }
                            >
                                <Fa.FaTrash/>
                            </Button>
                            <Button
                                size="xs"
                                variant={"subtle"}
                                onClick={() =>
                                    dispatch(actions.service_request.openEditor(idx))
                                }
                            >
                                <Fa.FaPen/>
                            </Button>
                        </Flex>
                    </Flex>
                </Card.Header>
                <Card.Body>
                    <Flex
                        direction={{base: "column", md: "row"}}
                        gap={2}
                        align={{base: "start", md: "center"}}
                        justify={"space-between"}
                        mt={{sm: "0", md: "-28px"}}
                        color={"gray.500"}
                    >
                        <Text fontSize="sm">
                            <strong>Typ:</strong> {data.type}
                        </Text>
                        <Text fontSize="sm">
                            <strong>IMEI / SN:</strong> {data.imei}
                        </Text>
                        <Text fontSize="sm">
                            <strong>Druh:</strong>{" "}
                            {data.is_warranty ? "Záruční" : "Pozáruční"}
                        </Text>
                        <Text fontSize="sm">
                            <strong>Reklamace č.:</strong> {data.num_at_card}
                        </Text>
                    </Flex>
                </Card.Body>
            </Card.Root>
        </>
    );
};
export default Device;
