import {
    Button,
    Flex,
    Fa,
    MenuTrigger,
    MenuContent,
    MenuItem,
    Badge,
    useColorModeValue,
    Avatar,
    SkeletonCircle,
    Skeleton,
    ColorModeButton,
    MenuRoot,
    Status,
} from "../../items";
import { useRedux } from "src/redux/provider";
import SidebarResponsive from "../Sidebar/SidebarResponsive";
import NavbarAlerts from "./navbarAlerts";

export default function HeaderLinks() {
    const { useStore, dispatch, actions } = useRedux();
    let navbarIcon = useColorModeValue("gray.500", "gray.200");

    function UserInfoAvatar() {
        if (useStore.users.isAuth) {
            return (
                <Avatar
                    size="sm"
                    src={`data:image/png;base64,${useStore.users.logged?.id}`}
                />
            );
        } else {
            return (
                <Flex direction={"row"}>
                    <SkeletonCircle size="10"/>
                </Flex>
            );
        }
    }

    function UserInfoName() {
        if (useStore.users.isAuth) {
            return <>{useStore.users.logged?.unique_fullname}</>;
        } else {
            return (
                <Flex direction={"row"} w={"100%"}>
                    <Skeleton height="20px" width={"100px"}/>
                </Flex>
            );
        }
    }

    return (
    
        <Flex
            pe={{sm: "0px", md: "16px"}}
            w={{sm: "100%", md: "auto"}}
            mt={{sm: "15px", md: "0px"}}
            alignItems="center"
            justifyItems={"end"}
            flexDirection="row"
            maxHeight={"18px"}
        >
            <Badge
                display={{sm: "none", md: "block"}}
                borderRadius={"15px"}
                alignContent={"center"}
                px={"10px"}
            >
                <Flex
                    align={"center"}
                    alignItems={"center"}
                    alignContent={"center"}
                    alignSelf={"center"}
                    gap={2}
                >
                    <Status value={useStore.core.signalr.state ? "success" : "error"}/>
                    SignalR
                </Flex>
            </Badge>
            <MenuRoot>
                <MenuTrigger>
                    <Button
                        me={{sm: "2px", md: "16px"}}
                        color={navbarIcon}
                        variant="ghost"
                        cursor={"pointer"}
                    >
                        <UserInfoAvatar/>
                        <UserInfoName/>
                    </Button>
                </MenuTrigger>
                <MenuContent p="16px 8px">
                    <Flex flexDirection="column" fontSize={"sm"}>
                        <MenuItem disabled={useStore.core.loading} value="NbLinkProfile">
                            <Fa.FaUser style={{marginRight: "15px"}}/> Profil
                        </MenuItem>
                        <MenuItem disabled={useStore.core.loading} value="NbLinkSettings">
                            <Fa.FaCog style={{marginRight: "15px"}}/> Nastavení
                        </MenuItem>
                        <MenuItem
                            disabled={useStore.core.loading}
                            value="NbLinkLogOff"
                            onClick={() => {
                                dispatch(actions.users.LogOut());
                            }}
                        >
                            <Fa.FaLock style={{marginRight: "15px"}}/> Odhlásit se
                        </MenuItem>
                    </Flex>
                </MenuContent>
            </MenuRoot>
            <NavbarAlerts/>
            <ColorModeButton ms={2}/>
            <SidebarResponsive/>
        </Flex>
    );
}
