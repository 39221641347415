import React, {useEffect} from "react";
import {CameraActionPanel, CameraCaptureEffect, CameraModule} from "./components";
import {childPostParent, handleGetFileName} from "./handlers";
import ReactCamera from "react-webcam";
import {CameraFile} from "src/types/camera";
import {useRedux} from "src/redux/provider";
import {signalR} from "src/lib";
import {
    DrawerBody,
    DrawerCloseTrigger,
    DrawerContent,
    DrawerRoot,
} from "src/components";
import {ImageEditor} from "src/components/ImageEditor";


export function Camera() {
    const {useStore, dispatch, actions} = useRedux();
    const cameraRef = React.useRef<ReactCamera>(null);
    const [downloaded, setDownloaded] = React.useState<Array<string>>([]);

    const onFileCaptureInternal = (hasInput: boolean, e?: any) => {
        let img = null;
        if (hasInput) {
            const files = e.target.files;
            if (files) {
                const file = files[0];
                const reader = new FileReader();
                reader.onload = (e) => {
                    img = e.target?.result;
                    const cameraFile = {
                        content: img,
                        type: useStore.core.camera.documentType,
                        name: `${handleGetFileName()}.jpg`,
                    } as CameraFile;
                    onFileDispatch(cameraFile);
                };
                reader.readAsDataURL(file);
            }
        } else {
            if (cameraRef.current === null) return;
            img = cameraRef.current?.getScreenshot();
            const cameraFile = {
                content: img,
                type: useStore.core.camera.documentType,
                name: `${handleGetFileName()}.jpg`,
            } as CameraFile;
            onFileDispatch(cameraFile);
        }
    }

    const onFileDispatch = (cameraFile: CameraFile) => {
        dispatch(actions.core.setCameraLoading(true));
        dispatch(actions.core.setCameraShowEffect(true));
        setTimeout(() => {
            dispatch(actions.core.setCameraShowEffect(false));
            dispatch(actions.core.setCameraLoading(false));
        }, 100);

        if (!useStore.core.camera.asChild)
            dispatch(actions.core.setCameraFile(cameraFile));

        if (useStore.core.camera.asChild)
            childPostParent(useStore.core.camera.parentId ?? "", cameraFile);
    }

    useEffect(() => {
        if (document.body.clientWidth < 600) {
            dispatch(actions.core.setCameraResolution(
                {
                    height: 1440,
                    width: 900,
                    facingMode: "enviroment",
                }));
        }

        if (useStore.core.camera.parentId && !useStore.core.camera.isConnectParent) {
            signalR.sendMessage("ConnectMobile", useStore.core.camera.parentId);
        }

        signalR.onMessageReceived("ConnectMobile", (res) => {
            dispatch(actions.core.setCameraConnectParent(true));
        });

        const handleChangeDocType = (res: any) =>
            dispatch(actions.core.setCameraDocumentType(res));

        signalR.onMessageReceived("ChangeDocType", handleChangeDocType);

        if (!useStore.core.camera.asChild) {
            signalR.sendMessage("RequestConnectMobile", "");
            const handleFetchupFile = (res: any) => {
                console.log("FetchupFile", res);
                if (!downloaded.includes(res)) {
                    setDownloaded((prevDownloaded) => [...prevDownloaded, res]);
                    dispatch(actions.core.extra.GetTempFile(res));
                }
            };

            signalR.onMessageReceived("FetchupFile", handleFetchupFile);

            return () => {
                signalR.offMessageReceived("FetchupFile", handleFetchupFile);
                signalR.offMessageReceived("ChangeDocType", handleChangeDocType);
            };
        }
    }, [useStore.core.camera.asChild]);

    return (
        <DrawerRoot
            open={useStore.core.camera.open}
            closeOnEscape={true}
            closeOnInteractOutside={true}
            size={"full"}
        >
            <DrawerContent>
                {!useStore.core.camera.asNoClose && (<DrawerCloseTrigger zIndex={1}
                                                                         borderRadius={"50%"}
                                                                         py={2}
                                                                         border={"1px solid #f8f8f8"}
                                                                         onClick={() => dispatch(actions.core.setCameraOpen({
                                                                             state: false,
                                                                             files: []
                                                                         }))}/>)}

                {useStore.core.camera.open && (
                    <DrawerBody>
                        {useStore.core.camera.editor.open && <ImageEditor/>}
                        {useStore.core.camera.showEffect && <CameraCaptureEffect/>}
                        {!useStore.core.camera.openMobile &&
                            <CameraModule ref={cameraRef} resolution={useStore.core.camera.resolution}/>}
                        <CameraActionPanel onCaptureFile={() => onFileCaptureInternal(false)}
                                           files={useStore.core.camera.files}
                                           onSelectFiles={(e) => onFileCaptureInternal(true, e)}
                                           onFileRemove={(e) => dispatch(actions.core.setCameraFileRemove(e))}/>

                    </DrawerBody>
                )}
            </DrawerContent>
        </DrawerRoot>
    )
};
