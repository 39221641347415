import {AddressTypes} from "src/enums";
import {CustomerAddress} from "src/types/service_request";
import {useRedux} from "src/redux/provider";

export default function PosamSk() {
    const {useStore, dispatch, actions} = useRedux();
    if (
        (useStore.service_request.customer.email ?? "")
            .toLocaleLowerCase()
            .indexOf("@posam.sk") > -1 &&
        useStore.service_request.invoice_address === null
    ) {
        const new_address = {
            type: AddressTypes.INVOICE,
            first_name: "PosAm, spol. s r.o.",
            last_name: "",
            email: useStore.service_request.customer.email,
            phone: "+421 249 239 052",
            street: "Pribinova 40",
            city: "Bratislava",
            postal_code: "811 09",
            country: "SK",
            company_id: "31365078",
        } as CustomerAddress;

        dispatch(
            actions.shared.signalR.retrieveCompanyInfo(
                "SK",
                "31365078"
            )
        );

        dispatch(
            actions.service_request.saveCustomer({
                ...useStore.service_request.customer,
                ...new_address,
                last_name: ".",
                type: AddressTypes.CUSTOMER,
            })
        );

        dispatch(actions.service_request.setInvoiceType("2"));

        dispatch(
            actions.service_request.saveAddress({
                ...new_address,
            })
        );
        dispatch(actions.service_request.nextStep());
    }

    return null;
}
