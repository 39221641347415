import Enhancer, {Generate} from "src/redux/enhancer";
import * as reducers from "./servicerequest.reducers";
import {AddressTypes, EnhancersFrom, signalR} from "src/enums";
import {serviceRequest} from "src/enums/signalR";
import {ServiceRequest} from "src/types/service_request";

export const signalRActions = (storeName: string) => {
    return {
        retrieveMyAddress: (type: AddressTypes) =>
            Generate(storeName, {
                addressType: type === AddressTypes.CUSTOMER ? "Zákazník" : AddressTypes.PICKUP ? "Svoz" : AddressTypes.DELIVERY ? "Doručení" : "Fakturační",
            }, "getMyAddress", EnhancersFrom.signalR, serviceRequest.retrieveMyAddress),

        validatePickupDate: (country: "CZ" | "SK", date: string) =>
            Generate(storeName, {
                country,
                date
            }, "setPickupDate", EnhancersFrom.signalR, signalR.shared.validatePickupDate),

        createServiceRequest: (data: ServiceRequest) =>
            Generate(storeName, {data}, "createServiceRequest", EnhancersFrom.signalR, signalR.serviceRequest.createServiceRequest),
    }
}

export const extraActions = (storeName: string) => {
    return {
        storeWarrantyTempFile: (files: File[]) =>
            Generate(storeName, files, "addWFile", EnhancersFrom.sandbox),

        storeReceiptTempFile: (files: File[]) =>
            Generate(storeName, files, "addReceiptFile", EnhancersFrom.sandbox),

        storeDamageTempFile: (files: File[]) =>
            Generate(storeName, files, "addDamageFile", EnhancersFrom.sandbox),
    }
};

export function ActionsEnhancer<T>(action: T, storeName: string) {
    return Enhancer(action, signalRActions(storeName), {}, extraActions(storeName));
}