import {CustomerAddress, ServiceRequest} from "src/types/service_request";
import {IFile} from "src/types/shared";
import {AddressTypes} from "src/enums";

export const useInvoiceCompany = (
    state: ServiceRequest,
    action: { payload: boolean }
) => {
    state.invoice_company_open = action.payload;
};

export const addDevice = (state: ServiceRequest) => {
    state.devices.push({
        vendor: "",
        model: "",
        imei: "",
        num_at_card: "",
        type: "",
        serial_number: "",
        damage: "",
        defect: "",
        purchase_date: null,
        is_warranty: true,
        estimated_repair_cost: 550,
        warranty_files: [],
        receipt_files: [],
        damage_files: [],
        is_backup: false,
        is_failed_backup_return: false,
        is_saved: false,
    });
    state.device_edit.idx = state.devices.length - 1;
    state.device_edit.is_open = true;
};

const new_address = {
    type: AddressTypes.PICKUP,
    first_name: "",
    last_name: "",
    phone: "",
    street: "",
    city: "",
    postal_code: "",
    country: "",
    company_id: "",
} as CustomerAddress;

export const addWFile = (
    state: ServiceRequest,
    action: { payload: IFile[] }
) => {
    action.payload.forEach((f) => {
        if (f.record_id === undefined || f.record_id === null) f.record_id = -1;

        let file = state.devices[state.device_edit.idx].warranty_files
            .findIndex((x) => x.name === f.name);


        if (file === -1)
            state.devices[state.device_edit.idx]
                .warranty_files.push({name: f.name, record_id: -1, size: f.size, type: f.type});

        if (file > -1)
            state.devices[state.device_edit.idx]
                .warranty_files[file] = {
                ...state.devices[state.device_edit.idx]
                    .warranty_files[file], record_id: f.record_id
            };
    });
};

export const addReceiptFile = (
    state: ServiceRequest,
    action: { payload: IFile[] }
) => {
    action.payload.forEach((f) => {
        if (f.record_id === undefined || f.record_id === null) f.record_id = -1;

        let file = state.devices[state.device_edit.idx].receipt_files
            .findIndex((x) => x.name === f.name);


        if (file === -1)
            state.devices[state.device_edit.idx]
                .receipt_files.push({name: f.name, record_id: -1, size: f.size, type: f.type});

        if (file > -1) {
            state.devices[state.device_edit.idx]
                .receipt_files[file] = {
                ...state.devices[state.device_edit.idx]
                    .receipt_files[file], record_id: f.record_id
            };
        }
    });
};

export const addDamageFile = (
    state: ServiceRequest,
    action: { payload: IFile[] }
) => {
    action.payload.forEach((f) => {
        if (f.record_id === undefined || f.record_id === null) f.record_id = -1;

        let file = state.devices[state.device_edit.idx].damage_files
            .findIndex((x) => x.name === f.name);


        if (file === -1)
            state.devices[state.device_edit.idx]
                .damage_files.push({name: f.name, record_id: -1, size: f.size, type: f.type});

        if (file > -1)

            state.devices[state.device_edit.idx]
                .damage_files[file] = {
                ...state.devices[state.device_edit.idx]
                    .damage_files[file], record_id: f.record_id
            };
    });
};

export const closeEditor = (state: ServiceRequest) => {
    state.device_edit.idx = -1;
    state.device_edit.is_open = false;
};

export const setAllOk = (
    state: ServiceRequest,
    action: { payload: boolean }
) => {
    state.all_is_ok = action.payload;
};

export const saveDevice = (
    state: ServiceRequest,
    action: { payload: any }
) => {
    console.log(action.payload);
    state.devices[state.device_edit.idx] = {
        ...state.devices[state.device_edit.idx],
        ...action.payload,
    };
};

export const setInvoiceType = (
    state: ServiceRequest,
    action: { payload: string }
) => {
    state.invoice_type = action.payload;
};

export const setPickupDate = (
    state: ServiceRequest,
    action: { payload: string }
) => {
    state.pickup_date = action.payload;
};

export const setPickupType = (
    state: ServiceRequest,
    action: { payload: string }
) => {
    state.pickup_type = action.payload;
    switch (action.payload) {
        case "1":
            state.pickup_address = null;
            break;
        case "2":
            state.pickup_address = {
                ...state.customer,
                type: AddressTypes.PICKUP,
            };
            break;
        case "3":
            state.pickup_address = {
                ...(state.invoice_address ?? new_address),
                type: AddressTypes.PICKUP,
            };
            break;
        case "4":
            state.pickup_address = {
                ...new_address,
                type: AddressTypes.PICKUP,
            };
    }
};

export const setDeliveryType = (
    state: ServiceRequest,
    action: { payload: string }
) => {
    state.delivery_type = action.payload;
    switch (action.payload) {
        case "1":
            state.delivery_address = null;
            break;
        case "2":
            state.delivery_address = {
                ...state.customer,
                type: AddressTypes.DELIVERY,
            };
            break;
        case "3":
            state.delivery_address = {
                ...(state.invoice_address ?? new_address),
                type: AddressTypes.DELIVERY,
            };
            break;
        case "4":
            state.delivery_address = {
                ...(state.pickup_address ?? new_address),
                type: AddressTypes.DELIVERY,
            };
            break;
        case "5":
            state.delivery_address = {...new_address, type: AddressTypes.DELIVERY};
            break;
    }
};

export const saveCustomer = (
    state: ServiceRequest,
    action: { payload: CustomerAddress }
) => {
    state.customer = action.payload;
};

export const setEmail = (
    state: ServiceRequest,
    action: { payload: string }
) => {
    state.customer.email = action.payload;
};

export const saveAddress = (
    state: ServiceRequest,
    action: { payload: CustomerAddress }
) => {
    if (action.payload.type === AddressTypes.CUSTOMER)
        state.customer = action.payload;
    if (action.payload.type === AddressTypes.INVOICE)
        state.invoice_address = action.payload;
    if (action.payload.type === AddressTypes.DELIVERY)
        state.delivery_address = action.payload;
    if (action.payload.type === AddressTypes.PICKUP)
        state.pickup_address = action.payload;
};

export const openEditor = (
    state: ServiceRequest,
    action: { payload: number }
) => {
    state.device_edit.idx = action.payload;
    state.device_edit.is_open = true;
};

export const createServiceRequest = (state: ServiceRequest, action: { payload: string }) => {
    state.is_send = action.payload === "";
    state.any_error = action.payload;
}

export const getMyAddress = (
    state: ServiceRequest,
    action: { payload: any }
) => {
    const data = action.payload ?? [];
    state.selectable_address = action.payload.map((x: any) => {
        return {
            city: x.city,
            street: x.street,
            postal_code: x.zipcode,
            country: x.country.isO2,
            first_name: x.first_name,
            last_name: x.sur_name,
            type: x.type.id,
            phone: x.phone,
            email: x.email
        } as CustomerAddress
    });
};

export const setAddressDialog = (
    state: ServiceRequest,
    action: { payload: { open: boolean; isPickup: boolean; isInvoice?: boolean } }
) => {
    state.address_dialog = action.payload;
};

export const removeDevice = (
    state: ServiceRequest,
    action: { payload: number }
) => {
    state.devices.splice(action.payload, 1);
};

export const beforeStep = (state: ServiceRequest) => {
    state.activeStep--;
};

export const nextStep = (state: ServiceRequest) => {
    state.activeStep++;
};

export const setState = (
    state: ServiceRequest,
    action: { payload: ServiceRequest }
) => {
    state = action.payload;
};


