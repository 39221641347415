import {createSlice} from "@reduxjs/toolkit";
import {initialState} from "src/redux/features/shared/shared.init";
import * as reducers from "./shared.reducers";
import {ActionsEnhancer} from "./shared.actions";

const name = "shared";

const SharedSlice = createSlice({
    name,
    initialState,
    reducers,
});

export const SharedReducer = SharedSlice.reducer;
export const SharedActions = ActionsEnhancer(SharedSlice.actions, name);
