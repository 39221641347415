import logo_h from "../assets/img/rts_h.png";
import logo_v from "../assets/img/rts_v.png";
import {
    Flex,
    Image,
    AbsoluteCenter,
    Center,
    Card,
    ColorModeButton,
    Text,
    useColorModeValue,
    Avatar,
    Button,
    useLocation,
    useEffect, useNavigate
} from "src/items"
import {useRedux} from "src/redux/provider";
import React from "react";

function Unauthorized() {
    const sub_logo = useColorModeValue(logo_h, logo_h);
    const web_app_logo = useColorModeValue(logo_v, logo_v);
    const textColor = useColorModeValue("gray.400", "white");
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    const {useStore, dispatch, actions} = useRedux();
    const photo = useStore.users.logged?.id ? `data:image/png;base64,${useStore.users.logged.id}` : "";

    useEffect(() => {
        if (code) {
            if (code.length > 0) {
                navigate(localStorage.getItem("lastPath") ?? "/");
                dispatch(actions.users.signalR.LogIn(code));
            }
        }
    }, [code]);
    const LogIn = () => {
        let redirectPattern = "/api/page/login/oauth2";

        if (location.pathname.indexOf("/auth2") === -1)
            localStorage.setItem("lastPath", location.pathname + location.search);

        if (window.location.host === "localhost:3000")
            redirectPattern = "https://localhost:7082/api/page/login/oauth2/3000";

        window.location.replace(redirectPattern);
    };

    return (
        <>
            <Flex
                w={"100%"}
                h={"100vh"}
            >
                <Image
                    position={"absolute"}
                    display={{base: "none", md: "block"}}
                    left={0}
                    top={0}
                    maxW={"480px"}
                    maxH={"92px"}
                    src={sub_logo}
                    alignSelf={"center"}
                    mt={2}
                    ms={2}
                />
                <AbsoluteCenter bg={"transparent"}>
                    <Center bg={"transparent"}>
                        <Card.Root
                            maxWidth={"450px"}
                            minWidth={"450px"}
                            px={4}
                            backdropFilter={"blur(21px)"}
                        >
                            <Card.Header justifyContent={"right"} w={"100%"}>
                                <Flex position={"absolute"} right={2} top={2}>
                                    <ColorModeButton/>
                                </Flex>
                            </Card.Header>
                            <Card.Body
                                w={"100%"}
                                textAlign={"center"}
                                alignContent={"center"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                justifyItems={"center"}
                            >
                                <Flex
                                    direction={"row"}
                                    justifySelf={"center"}
                                    align={"center"}
                                    justify={"center"}
                                    mb={22}
                                >
                                    <Image
                                        src={web_app_logo}
                                        alignSelf={"center"}
                                        maxH={"300px"}
                                        maxW={"480px"}
                                    />
                                </Flex>

                                <Text
                                    alignSelf={"center"}
                                    mb="36px"
                                    ms="4px"
                                    color={textColor}
                                    fontWeight="bold"
                                    fontSize="14px"
                                >
                                    {!useStore.users.isAuth && (
                                        <>
                                            Aby jsi mohl pokračovat ve svém záměru tak se musíš
                                            přihlásit. Přihlášení tě přesměruje na autorizační server.
                                        </>
                                    )}
                                </Text>
                                {/*{useStore.core.isError && (*/}
                                {/*    <Alert title="Chyba" status="error">*/}
                                {/*        {message}*/}
                                {/*    </Alert>*/}
                                {/*)}*/}

                                {!useStore.users.isAuth && (
                                    <Card.Root p={2} py={-2} w={"100%"}>
                                        <Flex direction={"row"} align={"center"} w={"100%"}>
                                            <Avatar
                                                borderRadius={"15px"}
                                                ms={2}
                                                mr={6}
                                                src={photo}
                                            />
                                            <Button
                                                fontSize="14px"
                                                type="submit"
                                                variant={"subtle"}
                                                h="45"
                                                w={"75%"}
                                                mr={2}
                                                mb="20px"
                                                mt="20px"
                                                onClick={LogIn}
                                                loading={!!code}
                                                disabled={useStore.core.loading}
                                            >
                                                Přihlásit se
                                            </Button>
                                        </Flex>
                                    </Card.Root>
                                )}
                            </Card.Body>
                            <Card.Footer>
                                <Flex
                                    direction={"row"}
                                    w={"100%"}
                                    align={"center"}
                                    justify={"center"}
                                >
                                    &copy; 2024 - {new Date().getFullYear()},{" "}
                                    <Text as="span">
                                        BRITEX-CZ, s.r.o. by <a>Crossui.eu</a>
                                    </Text>
                                </Flex>
                            </Card.Footer>
                        </Card.Root>
                    </Center>
                </AbsoluteCenter>
            </Flex>
        </>
    );
}

export default Unauthorized;
