import { dialogAnatomy, slotRecipe } from "../recipe.anatomy";

export const dialogSlotRecipe = slotRecipe({
  className: "chakra-dialog",
  slots: dialogAnatomy.keys(),
  base: {
    content: {
      borderRadius: "15px",
    },
  },
});

