import SoDetailApi from "./components/so.detail.api";
import SoDetailCustomer from "./components/so.detail.customer";
import SoDetailItems from "./components/so.detail.items";
import SoDetailDocuments from "./components/so.detail.documents";
import React from "react";
import SoTimeLine from "./components/so.detail.timeline";
import {
    Ai,
    Avatar,
    Badge,
    Button, DialogBody, DialogCloseTrigger, DialogContent, DialogHeader, DialogRoot, DialogTrigger,
    DrawerBackdrop,
    DrawerBody,
    DrawerCloseTrigger,
    DrawerContent, DrawerFooter,
    DrawerHeader,
    DrawerRoot,
    DrawerTitle,
    Fa,
    Flex,
    Grid,
    GridItem,
    Io,
    Lu,
    Md,
    Text
} from "src/components";

export default function SoDetail(props: {
    isOpen: boolean;
    onClose: () => void;
}) {
    const [state, setState] = React.useState("customer");

    const StateViewer = () => {
        switch (state) {
            case "customer":
                return <SoDetailCustomer/>;
            case "items":
                return <SoDetailItems/>;
            case "documents":
                return <SoDetailDocuments/>;
            case "api":
                return <SoDetailApi/>;
            case "timeline":
                return <SoTimeLine/>;
            default:
                return <SoDetailCustomer/>;
        }
    };

    return (
        <>
            <DrawerRoot open={props.isOpen} size="xl">
                <DrawerBackdrop/>
                <DrawerContent>
                    <DrawerHeader>
                        <DrawerTitle>Zakázka č. 4001111</DrawerTitle>
                        <Badge p={2} colorPalette="green">
                            Stav zakázky
                        </Badge>
                    </DrawerHeader>
                    <DrawerBody gap={2}>
                        <Grid gap={2} templateColumns={"repeat(4, 1fr)"}>
                            <GridItem
                                gap={2}
                                p={4}
                                h={"100vh"}
                                borderRight={"1px solid var(--rts-colors-gray-700)"}
                            >
                                <Flex
                                    direction={"column"}
                                    gap={2}
                                    justify={"center"}
                                    align={"center"}
                                >
                                    <Avatar
                                        size={"2xl"}
                                        borderRadius={"12px"}
                                        src={"https://bit.ly/broken-link"}
                                    />
                                    <Text fontSize={"sm"} fontWeight={"bold"}>
                                        SAMSUNG
                                    </Text>
                                    <Text fontSize={"sm"}>SM-G988SMTETL</Text>
                                    <Button
                                        w={"100%"}
                                        size={"md"}
                                        mt={4}
                                        variant={"outline"}
                                        colorScheme={"blue"}
                                        gap={2}
                                        onClick={() => setState("customer")}
                                    >
                                        <Lu.LuUser2/>
                                        Zákazník
                                    </Button>
                                    <Button
                                        w={"100%"}
                                        size={"md"}
                                        variant={"outline"}
                                        colorScheme={"blue"}
                                        gap={2}
                                        onClick={() => setState("device")}
                                    >
                                        <Md.MdOutlineDevices/>
                                        Přístroj
                                    </Button>
                                    <Button
                                        w={"100%"}
                                        size={"md"}
                                        variant={"outline"}
                                        colorScheme={"blue"}
                                        gap={2}
                                        onClick={() => setState("items")}
                                    >
                                        <Lu.LuLayers/>
                                        Materiál
                                    </Button>
                                    <Button
                                        w={"100%"}
                                        size={"md"}
                                        variant={"outline"}
                                        colorScheme={"blue"}
                                        gap={2}
                                        onClick={() => setState("work")}
                                    >
                                        <Md.MdWorkOutline/>
                                        Práce
                                    </Button>
                                    <Button
                                        w={"100%"}
                                        size={"md"}
                                        variant={"outline"}
                                        colorScheme={"blue"}
                                        gap={2}
                                        onClick={() => setState("documents")}
                                    >
                                        <Ai.AiOutlineFileZip/>
                                        Dokumenty
                                    </Button>
                                    <Button
                                        w={"100%"}
                                        size={"md"}
                                        variant={"outline"}
                                        colorScheme={"blue"}
                                        gap={2}
                                        onClick={() => setState("documents")}
                                    >
                                        <Ai.AiFillTruck/>
                                        Doprava
                                    </Button>
                                    <Button
                                        w={"100%"}
                                        size={"md"}
                                        variant={"outline"}
                                        colorScheme={"blue"}
                                        gap={2}
                                        onClick={() => setState("timeline")}
                                    >
                                        <Md.MdTimeline/>
                                        Události
                                    </Button>
                                    <Button
                                        w={"100%"}
                                        size={"md"}
                                        variant={"outline"}
                                        colorScheme={"blue"}
                                        gap={2}
                                        onClick={() => setState("history")}
                                    >
                                        <Lu.LuHistory/>
                                        Stavová osa
                                    </Button>
                                    <Button
                                        w={"100%"}
                                        size={"md"}
                                        variant={"outline"}
                                        colorScheme={"blue"}
                                        gap={2}
                                        onClick={() => setState("comments")}
                                    >
                                        <Ai.AiOutlineComment/>
                                        Komentáře
                                    </Button>
                                    <Button
                                        w={"100%"}
                                        size={"md"}
                                        variant={"outline"}
                                        colorScheme={"blue"}
                                        gap={2}
                                        onClick={() => setState("notifications")}
                                    >
                                        <Io.IoMdPaperPlane/>
                                        Notifikace
                                    </Button>
                                    <Button
                                        w={"100%"}
                                        size={"md"}
                                        variant={"outline"}
                                        colorScheme={"blue"}
                                        gap={2}
                                        onClick={() => setState("api")}
                                    >
                                        <Fa.FaNetworkWired/>
                                        API komunikace
                                    </Button>
                                </Flex>
                            </GridItem>
                            <GridItem colSpan={3} p={4}>
                                <StateViewer/>
                            </GridItem>
                        </Grid>
                    </DrawerBody>
                    <DrawerCloseTrigger onClick={props.onClose}/>
                    <DrawerFooter>
                        <DialogRoot
                            size="sm"
                            placement="center"
                            motionPreset="slide-in-bottom"
                        >
                            <DialogTrigger asChild>
                                <Button variant={"outline"} size={"sm"} gap={2}>
                                    <Lu.LuMoreVertical/>
                                    Další akce
                                </Button>
                            </DialogTrigger>
                            <DialogContent>
                                <DialogHeader>
                                    Další akce
                                    <DialogCloseTrigger/>
                                </DialogHeader>
                                <DialogBody>
                                    rem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </DialogBody>
                            </DialogContent>
                        </DialogRoot>

                        <Button variant={"outline"} size={"sm"} gap={2}>
                            <Lu.LuCamera/>
                            Kamera
                        </Button>
                        <Button variant={"outline"} size={"sm"} gap={2}>
                            <Lu.LuSave/>
                            Uložit
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </DrawerRoot>
        </>
    );
}
