import {Result} from "src/types/signalR";
import {CoreMessage} from "src/types/core";
import {__sandBoxEndpoint, __server} from "src/config";
import {ConvertToIFiles} from "src/lib";
import {IFile} from "src/types/shared";

async function ErrorMessages<T>(action: any, store: any, response: Result<T>) {
    if (!response.is_success)
        store.dispatch({
            type: "core/newMessage", result: null, payload: {
                title: response.is_success ? "Úspěch" : "Chyba",
                message: response.message,
                serverity: response.is_success ? "success" : "error",
                duration: 5000
            } as CoreMessage
        })
}

async function fetchHelper<T>(action: any, store: any) {
    return fetch(__server + action.endpoint, {
        method: (action.method ?? "GET"),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + (localStorage.getItem("token") ?? "")
        },
        body: JSON.stringify(action.payload ?? null)
    }).then((response) => {
        return response.json() as T;
    }).catch((err: any) => {
        ErrorMessages(action, store, {
            is_success: false,
            message: err.message,
            response: null,
            statusCode: 500,
            exception: null
        });
        return {
            is_success: false,
            message: err.message,
            response: null,
            statusCode: 500,
            exception: null
        }
    });
}

async function SandBoxHelper<T>(action: any, store: any) {
    let converted = await ConvertToIFiles(action.payload);
    const result = await fetchHelper({
        ...action,
        method: "POST",
        endpoint: __sandBoxEndpoint,
        payload: JSON.stringify({files: converted})
    }, store).then((response: any) => response);
    let org: IFile[] = [];
    let timeouted: IFile[] = [];
    result.response?.map((file: any) => {
        org.push({
            name: file.fileName,
            record_id: file.fileId,
            size: 0,
            type: "",
        } as IFile);
        timeouted.push({
            name: file.fileName,
            record_id: -999,
            size: 0,
            type: "",
        } as IFile);
    });
    return {
        origin: org,
        timeouted: timeouted
    };
}

export function fetchMiddleware(store: any) {
    return (next: any) => async (action: any) => {
        if (action) {
            if ((action.from ?? "") === "fetch") {
                const result = await fetchHelper<Result<any>>(action, store).then((response) => response);
                return next({
                    ...action,
                    payload: result?.response
                });
            } else if ((action.from ?? "") === "sandbox") {
                next(action);
                const result = await SandBoxHelper<Result<any>>(action, store).then((response) => response);
                setTimeout(() => {
                    next({
                        ...action,
                        payload: result.origin
                    });
                }, 3000);
                return next({
                    ...action,
                    payload: result.timeouted
                });
            } else
                return next(action);
        }
    }
}