import {CustomerAddress} from "src/types/service_request";
import {AddressTypes} from "src/enums";
import {
    Alert,
    Center,
    DialogBody,
    DialogCloseTrigger,
    DialogContent, DialogFooter,
    DialogHeader,
    DialogRoot,
    DialogTitle, Field,
    Flex, Input, RadioCardRoot, Spinner, Text
} from "src/components";
import {Card_type} from "./form-controll";
import React from "react";
import {useRedux} from "src/redux/provider";

export default function AddressDialogIco() {
    const {useStore, dispatch, dispatchAsync, actions} = useRedux();
    const data = useStore.service_request;
    const open = useStore.service_request.invoice_company_open;
    const onClose = () =>
        dispatch(actions.service_request.useInvoiceCompany(false));

    const new_address = {
        type: AddressTypes.INVOICE,
        first_name: "",
        last_name: "",
        phone: "",
        street: "",
        city: "",
        postal_code: "",
        country: "CZ",
        company_id: "",
    } as CustomerAddress;

    const __handleSave = () => {
        const api_data = useStore.shared.api_ico;
        if (api_data) {
            dispatch(
                actions.service_request.saveAddress({
                    ...(data.invoice_address ?? new_address),
                    country: api_data?.country ?? data.invoice_address?.country ?? "CZ",
                    company_id: api_data?.ico ?? "",
                    street: api_data?.street ?? "",
                    city: api_data?.city ?? "",
                    postal_code: api_data?.postal_code ?? "",
                    first_name: api_data?.subject ?? "",
                    contact_person: api_data?.subject ?? "",
                    type: AddressTypes.INVOICE,
                })
            );
        }
        if (data.customer.first_name === "") {
            dispatch(
                actions.service_request.saveAddress({
                    ...data.customer,
                    last_name: ".",
                    street: useStore.shared.api_ico?.street ?? "",
                    city: useStore.shared.api_ico?.city ?? "",
                    postal_code: useStore.shared.api_ico?.postal_code ?? "",
                    first_name: useStore.shared.api_ico?.subject ?? "",
                    country: api_data?.country ?? data.invoice_address?.country ?? "CZ",
                    type: AddressTypes.CUSTOMER,
                })
            );
        }

        onClose();
    };

    const __handleChangeCountry = (e: any) => {
        e.stopPropagation();
        dispatch(
            actions.service_request.saveAddress({
                ...(data.invoice_address ?? new_address),
                country: e.target.value,
                type: AddressTypes.INVOICE,
            })
        );
    };

    const __handleFetch = async (e: any) => {
        dispatch(
            actions.service_request.saveAddress({
                ...(data.invoice_address ?? new_address),
                company_id: e.target.value,
                first_name: "",
                type: AddressTypes.INVOICE,
            })
        );
        if (e.target.value.indexOf("_") === -1) {
            await dispatchAsync(
                actions.shared.signalR.retrieveCompanyInfo(
                    data.invoice_address?.country === "CZ" ? "CZ" : "SK",
                    e.target.value
                )
            );
        }
    };

    return (
        <DialogRoot size={"sm"} open={open}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Vyhledat podle IČO</DialogTitle>
                    <DialogCloseTrigger onClick={onClose}/>
                </DialogHeader>
                <DialogBody>
                    <RadioCardRoot
                        w={"100%"}
                        value={data.invoice_address?.country}
                        onChangeCapture={__handleChangeCountry}
                    >
                        <Flex direction={"column"} w={"100%"} gap={4}>
                            <Card_type
                                label={"Česká republika"}
                                descriptionTop={"Vyhledávání v systému ARES"}
                                value={"CZ"}
                                isPrice={false}
                            />
                            <Card_type
                                label={"Slovenská republika"}
                                descriptionTop={"Vyhledávání v systému RUZ"}
                                value={"SK"}
                                isPrice={false}
                            />
                        </Flex>
                    </RadioCardRoot>
                    <Field mt={4} label="IČO">
                        {" "}
                        <Input
                            mask={"99999999"}
                            placeholder="IČO"
                            value={data.invoice_address?.company_id}
                            onChange={(e: any) => __handleFetch(e)}
                        />
                    </Field>
                    {useStore.core.loading && (
                        <Center mt={4}>
                            <Spinner/>
                        </Center>
                    )}
                    {!useStore.core.loading && (
                        <RadioCardRoot
                            mt={4}
                            value={data.invoice_address?.first_name}
                            onChangeCapture={__handleSave}
                        >
                            <Flex direction={"column"} w={"100%"} gap={4}>
                                {useStore.shared.api_ico?.subject &&
                                    data.invoice_address?.company_id.indexOf("_") === -1 && (
                                        <Card_type
                                            label={useStore.shared.api_ico?.subject ?? ""}
                                            descriptionTop={
                                                <>
                                                    <Alert status={"info"}>
                                                        Fakturační adresu vložíte do požadavku kliknutím na
                                                        tento záznam.
                                                    </Alert>
                                                    <Text fontSize={"xs"}>
                                                        {useStore.shared.api_ico?.street}
                                                    </Text>
                                                    <Text fontSize={"xs"}>
                                                        {useStore.shared.api_ico?.city},{" "}
                                                        {useStore.shared.api_ico?.postal_code},{" "}
                                                        {useStore.shared.api_ico?.country}
                                                    </Text>
                                                </>
                                            }
                                            value={useStore.shared.api_ico?.subject ?? "-1"}
                                            isPrice={false}
                                        />
                                    )}
                            </Flex>
                        </RadioCardRoot>
                    )}
                </DialogBody>
                <DialogFooter></DialogFooter>
            </DialogContent>
        </DialogRoot>
    );
}
