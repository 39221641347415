import SidebarContent from "./SidebarContent";
import {
    useDisclosure,
    Flex,
    DrawerRoot,
    Fa,
    DrawerBackdrop,
    DrawerContent,
    DrawerCloseTrigger,
    DrawerBody,
    Box
} from "../../items";

function SidebarResponsive() {
    const {open, onOpen, onClose} = useDisclosure();
    return (
        <Flex display={{sm: "flex", xl: "none"}} alignItems="center">
            <Fa.FaBars onClick={onOpen}/>
            <DrawerRoot open={open} placement={"start"}>
                <DrawerBackdrop/>
                <DrawerContent
                    w="250px"
                    maxW="250px"
                    ms={{
                        sm: "16px",
                    }}
                    my={{
                        sm: "16px",
                    }}
                    borderRadius="16px"
                >
                    <DrawerCloseTrigger
                        _focus={{boxShadow: "none"}}
                        _hover={{boxShadow: "none"}}
                    />
                    <DrawerBody maxW="250px" px="1rem">
                        <Box maxW="100%" h="100vh">
                            <SidebarContent />
                        </Box>
                    </DrawerBody>
                </DrawerContent>
            </DrawerRoot>
        </Flex>
    );
}

export default SidebarResponsive;
