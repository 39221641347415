import {Card, Flex, Text, Avatar, Fa } from "src/components";

export default function SoDetailCustomer() {
  return (
    <Flex w={"100%"} direction={"column"}>
      <Card.Root w={"100%"}>
        <Card.Body>
          <Text fontSize={"sm"} fontWeight={"bold"}>
            Zákazník
          </Text>
          <Flex
            gap={2}
            direction={"row"}
            mt={4}
            justifyContent={"space-between"}
          >
            <Avatar size={"sm"} />
            <Text fontSize={"sm"}>Jméno Příjmení</Text>
            <Text fontSize={"sm"}></Text>
            <Text fontSize={"sm"}>Email</Text>
            <Text fontSize={"sm"}>Mobil</Text>
          </Flex>
        </Card.Body>
      </Card.Root>
      <Card.Root mt={2} w={"100%"}>
        <Card.Body>
          <Text fontSize={"sm"} fontWeight={"bold"}>
            Fakturace
          </Text>
          <Flex
            gap={2}
            direction={"row"}
            mt={4}
            justifyContent={"space-between"}
          >
            <Avatar size={"sm"} icon={<Fa.FaFileInvoiceDollar />} />
            <Text fontSize={"sm"}>Jméno Příjmení</Text>
            <Text fontSize={"sm"}></Text>
            <Text fontSize={"sm"}>Email</Text>
            <Text fontSize={"sm"}>Mobil</Text>
          </Flex>
        </Card.Body>
      </Card.Root>
      <Card.Root mt={2} w={"100%"}>
        <Card.Body>
          <Text fontSize={"sm"} fontWeight={"bold"}>
            Doručení
          </Text>
          <Flex
            gap={2}
            direction={"row"}
            mt={4}
            justifyContent={"space-between"}
          >
            <Avatar size={"sm"} icon={<Fa.FaTruck />} />
            <Text fontSize={"sm"}>Jméno Příjmení</Text>
            <Text fontSize={"sm"}></Text>
            <Text fontSize={"sm"}>Email</Text>
            <Text fontSize={"sm"}>Mobil</Text>
          </Flex>
        </Card.Body>
      </Card.Root>
    </Flex>
  );
}
