import { selectAnatomy, slotRecipe } from "../recipe.anatomy";
export const selectSlotRecipe = slotRecipe({
  className: "chakra-select",
  slots: selectAnatomy.keys(),
  base: {
    root: {
      borderRadius: "15px",
    },
    control: {
      borderRadius: "15px",
    },
    content: {
      p: 2,
      borderRadius: "15px",
    },
    trigger: {
      borderRadius: "15px",
    },
    item: {
      borderRadius: "15px",
    }
  },
});
