import { Box } from "../../items";
function PanelContainer(props: any) {
  const { variant, children, ...rest } = props;
  return (
    <Box
      css={{ p: "30px 15px", minHeight: "calc(100vh - 123px)" }}
      {...rest}
    >
      {children}
    </Box>
  );
}

export default PanelContainer;
