import {useState, useEffect, Portal, useDisclosure, Provider} from "src/items";
import {useRedux} from "src/redux/provider";
import routes from "../rts.routes";
import Sidebar from "../components/Sidebar/Sidebar";
import MainPanel from "../components/MainPanel/mainPanel";
import PanelContent from "../components/MainPanel/panelContent";
import PanelContainer from "../components/MainPanel/panelContainer";
import Navbar from "../components/NavBar/navbar";
import {Route, Routes, useLocation} from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Unauthorized from "../views/login.page";


export const MainLayout = () => {
    const [sidebarVariant, setSidebarVariant] = useState("transparent");
    const [fixed, setFixed] = useState(false);
    const location = useLocation();
    const {open, onOpen, onClose} = useDisclosure();
    const [activeRoute, setActiveRoute] = useState("Dashboard");
    const {useStore} = useRedux();
    const getRoute = () => {
        return window.location.pathname !== "/full-screen-maps";
    };

    useEffect(() => {
    }, [location.pathname]);

    const getActiveRoute = (routes: any) => {
        let activeRoute = process.env.REACT_APP_DEFAULT_PATH;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute: any = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else if (routes[i].category) {
                let categoryActiveRoute: any = getActiveRoute(routes[i].views);
                if (categoryActiveRoute !== activeRoute) {
                    return categoryActiveRoute;
                }
            } else {
                if (window.location.pathname === routes[i].path) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };

    const getActiveNavbar = (routes: any) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].category) {
                let categoryActiveNavbar: any = getActiveNavbar(routes[i].views);
                if (categoryActiveNavbar !== activeNavbar) {
                    return categoryActiveNavbar;
                }
            } else {
                if (window.location.pathname === routes[i].path) {
                    if (routes[i].secondaryNavbar) {
                        return routes[i].secondaryNavbar;
                    }
                }
            }
        }
        return activeNavbar;
    };

    const getRoutes: any = (routes: any[]) => {
        return routes?.map((prop: any, key: any) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.category === "navbar") {
                return getRoutes(prop.views);
            }
            if (prop.layout === "") {
                return (
                    <Route
                        path={prop.path}
                        element={prop.component}
                        errorElement={<Unauthorized/>}
                        key={key}
                    />
                );
            } else {
                return <></>;
            }
        });
    };

    return (
        <Provider>
            {!useStore.users.isAuth && <Unauthorized/>}
            {useStore.users.isAuth && (
                <>
                    <Sidebar/>
                    <MainPanel
                        width={{
                            base: "100%",
                            xl: "calc(100% - 275px)",
                        }}
                    >
                        <Portal>
                            <Navbar/>
                        </Portal>
                        {getRoute() ? (
                            <PanelContent>
                                <PanelContainer>
                                    <Routes>{getRoutes(routes)}</Routes>
                                </PanelContainer>
                            </PanelContent>
                        ) : null}
                        <Footer/>
                    </MainPanel>
                </>
            )}
        </Provider>
    );
};

