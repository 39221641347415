import { Box } from "../../items";

function PanelContent(props: any) {
  const { variant, children, ...rest } = props;
  return (
    <Box
      css={{
        ms: "auto",
        me: "auto",
        ps: "15px",
        pe: "15px",
        mt: { sm: "130px", md: "90px" },
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

export default PanelContent;
