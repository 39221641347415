import {useEffect} from "react";
import {
    Card_type,
    InvoiceAddress,
    PickupAddress, PickupCountry,
} from "./form-controll";
import {Alert, Button, DatePicker, Field, Flex, Input, Lu, RadioCardRoot} from "src/components";
import {useRedux} from "src/redux/provider";
import {Countries} from "src/enums";

export default function Transport() {
    const {useStore, dispatch, actions} = useRedux();
    const data = useStore.service_request;

    const pickupDates = () => {
        //Načteme si z API první svozový den
        let date = new Date(useStore.shared.first_pickup_date ?? new Date());

        let min = date.setDate(date.getDate());
        let max = date.setDate(date.getDate() + 31);
        return {min, max};
    };

    const datePickup = new Date(data.pickup_date ?? new Date());
    const countryCode = data.pickup_type === "2" ? data.customer.country : data.pickup_type === "3" ? data.invoice_address?.country ?? "CZ" : data.pickup_address?.country ?? "CZ";
    useEffect(() => {
        if (data.pickup_date === null || data.pickup_date === "") {
            dispatch(
                actions.service_request.setPickupDate(
                    new Date(pickupDates().min).toISOString().split("T")[0]
                )
            );
            dispatch(
                actions.shared.validatePickupDate(true)
            );
        }
    }, []);

    useEffect(() => {
        if (data.pickup_type !== "1")
            dispatch(actions.shared.signalR.getHolidays(countryCode === Countries.Czech ? Countries.Czech : Countries.Slovakia, new Date().getFullYear()));
    }, [data.pickup_type, data.pickup_address, data.invoice_address, data.customer]);

    return (
        <Flex direction={"column"} gap={4}>
            <Flex direction={"row"} gap={4}>
                <Field label="Způsob dopravy do servisu" helperText="Vyberte jednu z možností" required>
                    <RadioCardRoot
                        w={"100%"}
                        mt={4}
                        gap={2}
                        value={data.pickup_type}
                        onChangeCapture={(e: any) => {
                            dispatch(
                                actions.service_request.setPickupType(e.target.value)
                            );
                        }}
                    >
                        <Flex
                            direction={{base: "column", md: "row"}}
                            w="100%"
                            gap={2}
                        >
                            <Card_type
                                label={"Přinesu osobně"}
                                descriptionTop="Milady Horákové 125, Kladno, 272 01, CZ"
                                value={"1"}
                                isPrice
                                curr={"CZK"}
                                price={0.0}
                            />
                            <Card_type
                                label={"Svoz na adresu"}
                                descriptionTop={`${data.customer.street}, ${data.customer.city}, ${data.customer.postal_code}, ${data.customer.country}`}
                                value={"2"}
                                isPrice
                                curr={"CZK"}
                                price={0.0}
                            />
                            <Card_type
                                label={"Svoz na fakturační adresu"}
                                descriptionTop={`${data.invoice_address?.street}, ${data.invoice_address?.city}, ${data.invoice_address?.postal_code}, ${data.invoice_address?.country}`}
                                value={"3"}
                                isPrice
                                curr={"CZK"}
                                price={0.0}
                                display={data.invoice_type === "1" ? "none" : "flex"}
                            />
                            <Card_type
                                label={"Svoz na jinou adresu"}
                                descriptionTop={
                                    data.pickup_address?.first_name ? (
                                        `${data.pickup_address?.street}, ${data.pickup_address?.city}, ${data.pickup_address?.postal_code}, ${data.pickup_address?.country}`
                                    ) : (
                                        <Flex>
                                            <Alert
                                                title={"Adresa není doplněna"}
                                                status="warning"
                                                icon={<Lu.LuAlertTriangle/>}
                                            >
                                                {" "}
                                                Pro doplnění stiskni Plus v pravém rohu tohoto boxu.
                                            </Alert>
                                        </Flex>
                                    )
                                }
                                descriptionBottom={
                                    <Flex
                                        position={"absolute"}
                                        right={3}
                                        bottom={3}
                                        fontSize={"xx-large"}
                                    >
                                        <Button
                                            variant={"ghost"}
                                            disabled={data.pickup_type !== "4"}
                                            onClick={() =>
                                                dispatch(
                                                    actions.service_request.setAddressDialog({
                                                        open: true,
                                                        isPickup: true,
                                                    })
                                                )
                                            }
                                        >
                                            {!data.pickup_address?.first_name ? (
                                                <Lu.LuPlus/>
                                            ) : (
                                                <Lu.LuPenLine/>
                                            )}
                                        </Button>
                                    </Flex>
                                }
                                value={"4"}
                                isPrice
                                curr={"CZK"}
                                price={0.0}
                            />
                        </Flex>
                    </RadioCardRoot>
                </Field>
            </Flex>
            {(data.pickup_type ?? "1") !== "1" && (
                <>
                    <Flex direction={"row"} gap={4}>

                        <Field
                            label="Datum svozu"
                            errorText={
                                "Tento den není svozový vyberte prosím jiný datum svozu"
                            }
                            invalid={!useStore.shared.is_valid_pickup_date}
                        >
                            <DatePicker
                                selected={datePickup}
                                peekNextMonth={false}
                                value={datePickup.toLocaleString()}
                                dateFormat={"Pp"}
                                inline
                                holidays={useStore.shared.holidays.map((e) => ({date: e, holidayName: "Svátek"}))}
                                minDate={new Date(pickupDates().min)}
                                maxDate={new Date(pickupDates().max)}
                                monthsShown={2}
                                excludeWeekend
                                excludeDates={useStore.shared.holidays.map((e) => ({date: new Date(e)}))}
                                onChange={(date) => {
                                    dispatch(
                                        actions.service_request.setPickupDate((date ?? new Date(useStore.shared.first_pickup_date ?? new Date())).toISOString().split("T")[0])
                                    );
                                    dispatch(
                                        actions.shared.validatePickupDate(true)
                                    );
                                }}
                            />
                        </Field>
                    </Flex>
                </>
            )}
            <Flex direction={"row"} gap={4}>
                <Field label="Způsob dopravy ze servisu" helperText="Vyberte jednu z možností" required>
                    <RadioCardRoot
                        w={"100%"}
                        mt={4}
                        gap={2}
                        value={data.delivery_type}
                        onChangeCapture={(e: any) => {
                            dispatch(
                                actions.service_request.setDeliveryType(e.target.value)
                            );
                        }}
                    >
                        <Flex
                            direction={{base: "column", md: "row"}}
                            w="100%"
                            gap={2}
                        >
                            <Card_type
                                label={"Vyzvednu osobně"}
                                descriptionTop="Milady Horákové 125, Kladno, 272 01, CZ"
                                value={"1"}
                                isPrice
                                price={0.0}
                                curr="CZK"
                            />
                            <Card_type
                                label={"Přepravcem na adresu"}
                                descriptionTop={`${data.customer.street}, ${data.customer.city}, ${data.customer.postal_code}, ${data.customer.country}`}
                                value={"2"}
                                isPrice
                                price={0.0}
                                curr="CZK"
                            />
                            <Card_type
                                display={data.invoice_type === "1" ? "none" : "flex"}
                                label={"Přepravcem na fakturační adresu"}
                                descriptionTop={`${InvoiceAddress()}`}
                                value={"3"}
                                isPrice
                                price={0.0}
                                curr="CZK"
                            />
                            <Card_type
                                display={data.pickup_type !== "4" ? "none" : "flex"}
                                label={"Přepravcem na adresu svozu"}
                                descriptionTop={`${PickupAddress()}`}
                                value={"4"}
                                isPrice
                                price={0.0}
                                curr="CZK"
                            />
                            <Card_type
                                label={"Přepravcem na jinou adresu"}
                                descriptionTop={
                                    data.delivery_address?.first_name ? (
                                        `${data.delivery_address?.street}, ${data.delivery_address?.city}, ${data.delivery_address?.postal_code}, ${data.delivery_address?.country}`
                                    ) : (
                                        <Flex>
                                            <Alert
                                                title={"Adresa není doplněna"}
                                                status="warning"
                                                icon={<Lu.LuAlertTriangle/>}
                                            >
                                                {" "}
                                                Pro doplnění stiskni Plus v pravém rohu tohoto boxu.
                                            </Alert>
                                        </Flex>
                                    )
                                }
                                descriptionBottom={
                                    <Flex
                                        position={"absolute"}
                                        right={3}
                                        bottom={3}
                                        fontSize={"xx-large"}
                                    >
                                        <Button
                                            variant={"ghost"}
                                            disabled={data.delivery_type !== "5"}
                                            onClick={() =>
                                                dispatch(
                                                    actions.service_request.setAddressDialog({
                                                        open: true,
                                                        isPickup: false,
                                                    })
                                                )
                                            }
                                        >
                                            {!data.delivery_address?.first_name ? (
                                                <Lu.LuPlus/>
                                            ) : (
                                                <Lu.LuPenLine/>
                                            )}
                                        </Button>
                                    </Flex>
                                }
                                isPrice
                                price={0.0}
                                curr="CZK"
                                value={"5"}
                            />
                        </Flex>
                    </RadioCardRoot>
                </Field>
            </Flex>
        </Flex>
    );
}
