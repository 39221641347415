import {
    Avatar,
    Box,
    Button,
    Circle,
    Fa,
    Flex,
    HoverCardArrow,
    HoverCardContent,
    HoverCardRoot,
    HoverCardTrigger,
    Lu,
    Md,
    Tooltip,
    Text,
    Card,
    Checkbox, Tag
} from "src/components";
import {useRedux} from "src/redux/provider";
import dpd from "../../assets/img/carriers/dpd.png";
import britex from "../../assets/img/carriers/favicon.png";
import LpItemDetail from "./lp.itemdetail";
import {item} from "./lp.types";
import React from "react";
import {CardHover} from "src/theme";

export default function LpItem(props: { item: item }) {
    const {item} = props;
    const {useStore, dispatch, actions} = useRedux();

    const handleOpenDetail = () => {
        dispatch(actions.logistics_packages.setOpenDetail({
            data: {
                id: item.id,
                carrier: item.carrier,
                pack_code: item.pack_code,
                created_at: new Date(),
                updated_at: new Date(),
                carrier_image_url: "",
            },
            state: true
        }))
    }

    const AvatarType = () => {
        if (!item.is_return)
            return (
                <Tooltip content={"Příchozí zásilka"}>
                    <Avatar
                        size={"sm"}
                        bg={"orange.500"}
                        icon={<Lu.LuPackagePlus/>}
                    >
                        <Lu.LuPackagePlus/>
                    </Avatar>
                </Tooltip>
            );

        if (item.is_return)
            return (
                <Tooltip content={"Odchozí zásilka"}>
                    <Avatar
                        size={"sm"}
                        bg={"green.500"}
                        icon={<Lu.LuPackageMinus/>}
                    >
                        <Lu.LuPackageMinus/>
                    </Avatar>
                </Tooltip>
            );

        return (
            <Avatar size={"sm"} bg={"blue.500"} icon={<Fa.FaQuestion/>}/>
        );
    };

    const CodIcon = () => {
        const [open, setOpen] = React.useState(false);
        if (item.is_cod)
            return (
                <HoverCardRoot
                    size="sm"
                    open={open}
                    onOpenChange={(e: any) => setOpen(e.open)}
                >
                    <HoverCardTrigger asChild>
                        <Circle p={2} bg={"orange"}>
                            <Md.MdOutlineAttachMoney/>
                        </Circle>
                    </HoverCardTrigger>
                    <HoverCardContent maxWidth="240px">
                        <HoverCardArrow/>
                        <Box>
                            <Text fontSize={"xs"} fontWeight={"bold"}>
                                Dobírka
                            </Text>
                            <Text fontSize={"xs"}>Částka: {item.cod_price ?? 0} Kč</Text>
                        </Box>
                    </HoverCardContent>
                </HoverCardRoot>
            );
        return null;
    };

    const PrintLabel = () => {
        return (
            <Button
                size={"sm"}
                aria-label="Vytisknout štítek"
                variant={"outline"}
                disabled={!item.is_return}
                onClick={(e) => {
                    e.stopPropagation();
                    console.log("Print label");
                }}
            >
                <Lu.LuPrinter/>
            </Button>
        );
    };

    const Carrier = () => {
        switch (item.carrier) {
            case "DPD CZ":
                return dpd;
            default:
                return britex;
        }
    };

    return (
        <>
            
            <Card.Root
                variant={"subtle"}
                css={CardHover.card.hover}
                fontSize={"sm"}
                onClick={handleOpenDetail}
            >
                <Card.Body>
                    <Flex
                        gap={2}
                        direction={"row"}
                        justify={"space-between"}
                        align={"center"}
                    >
                        <Flex
                            gap={6}
                            direction={"row"}
                            justify={"flex-start"}
                            align={"center"}
                            minW={"10%"}
                        >
                            <Checkbox checked={item.is_checked}/>
                            <AvatarType/>
                            <CodIcon/>
                        </Flex>
                        <Flex direction={"row"} gap={4} align={"center"}>
                            <Avatar size={"md"} src={Carrier()}/>
                            <Flex direction={"column"}>
                                <Text fontWeight={"bold"}>Dopravce</Text>
                                <Text>{item.carrier}</Text>
                            </Flex>
                        </Flex>

                        <Flex direction={"column"}>
                            <Text fontWeight={"bold"}>Datum</Text>
                            <Text>{new Date(item.date).toLocaleDateString()}</Text>
                        </Flex>

                        <Flex direction={"column"}>
                            <Text fontWeight={"bold"}>Číslo přepravy</Text>
                            <Text>{item.pack_code}</Text>
                        </Flex>

                        <Flex direction={"column"}>
                            <Text fontWeight={"bold"}>Zákazník</Text>
                            <Text fontSize={"sm"}>{item.customer}</Text>
                            <Text fontSize={"xs"} color={"gray.500"}>
                                {item.customer_address}
                            </Text>
                        </Flex>

                        <Flex direction={"column"} gap={2} minW={"10%"}>
                            <Flex justify={"end"}>
                                <Tag fontSize={"xs"} fontWeight={"bold"}>
                                    {item.status}
                                </Tag>
                            </Flex>
                            <Flex justify={"end"}>
                                <PrintLabel/>
                            </Flex>
                        </Flex>
                    </Flex>
                </Card.Body>
            </Card.Root>
        </>
    );
}
