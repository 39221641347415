import {createSlice} from "@reduxjs/toolkit";
import * as reducers from "./servicerequest.reducers";
import {initialState} from "./servicerequest.init";
import {ActionsEnhancer} from "src/redux/features/service_request/servicerequest.actions";

const name = "serviceRequest";

const serviceRequestSlice = createSlice({
    name,
    initialState,
    reducers,
});
export const ServiceRequestReducer = serviceRequestSlice.reducer;
export const ServiceRequestActions = ActionsEnhancer(serviceRequestSlice.actions, name);

