import {useState} from "react";
import SoFilter from "./so.filter";
import SoItem from "./so.soitem";
import {BreadcrumbLink, Button, Card, Checkbox, Flex, Lu} from "src/components";

export default function SoPreview() {
    const [pageSize, setPageSize] = useState<number>(20);
    const data = {
        data: [
            {
                id: 4000111,
                manager: "fridrich",
                vendor: "SMSUNG",
                dev_type: "SM-TEST",
                dev_repair_type: "Záruční",
            },
        ],
        is_prev: false,
        is_next: false,
        pageNumber: 1,
        total: 1,
    };

    const BrItem = ({pageSizeInner}: { pageSizeInner: number }) => (
        <BreadcrumbLink
            onClick={() => {
                setPageSize(pageSizeInner);
                localStorage.setItem("pageSize", pageSizeInner.toString());
            }}
        >
            {pageSizeInner}
        </BreadcrumbLink>
    );

    return (
        <Flex w={"100%"} direction={"column"}>
            <Card.Root w={"100%"} mb={2}>
                <Card.Body>
                    <Flex
                        gap={2}
                        mb={2}
                        direction={"row"}
                        justifyContent={"space-between"}
                    >
                        <Flex gap={2} direction={"row"} justifyContent={"flex-start"}>
                            <Checkbox me={6}/>
                            <Button size={"sm"} variant={"subtle"}>
                                <Lu.LuCopy/>
                            </Button>
                            <Button size={"sm"} variant={"subtle"}>
                                <Lu.LuShuffle/>
                            </Button>
                        </Flex>
                        <SoFilter/>
                    </Flex>
                </Card.Body>
            </Card.Root>
            <SoItem
                item={{
                    id: 4000111,
                    manager: "fridrich",
                    vendor: "SMSUNG",
                    dev_type: "SM-TEST",
                    dev_repair_type: "Záruční",
                }}
            />
        </Flex>
    );
}
