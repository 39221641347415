import {createSlice} from "@reduxjs/toolkit";
import * as reducers from "./logistics_packages.reducers";
import {initialState} from "./logistics_packages.init";

const logisticsPackagesSlice = createSlice({
    name: "logisticsPackages",
    initialState,
    reducers,
});

export const LogisticsPackagesReducer = logisticsPackagesSlice.reducer;
export const LogisticsPackagesActions = logisticsPackagesSlice.actions;
