export enum shared {
    retrieveCompanyInfo= "RetrieveCompanyInfo",
    retrieveFirstPickupDate= "RetrieveFirstPickupDate",
    getHelp= "GetHelp",
    retrieveCountries= "RetrieveCountries",
    retrieveVendors= "RetrieveVendors",
    validateEmail= "ValidateEmail",
    verifyToken= "VerifyToken",
    isAuthorize = "IsAuthorize",
    validatePickupDate= "ValidatePickupDate",
    getHolidays= "GetHolidays",
}