import {IFile} from "src/types/shared";
import {CalculateSHA256} from "src/lib";

export async function ConvertToIFiles(files: File[]): Promise<IFile[]> {
    if (!files) return [];

    return await Promise.all(files.map(async (file) => {
        const arrayBuffer = await file.arrayBuffer();
        // base64 encoding
        const hashArray = Array.from(new Uint8Array(arrayBuffer));
        const base64String = btoa(hashArray.map((b) => String.fromCharCode(b)).join(""));

        return {
            name: file.name,
            type: file.type,
            size: file.size,
            record_id: -1,
            data: base64String,
            guid: await CalculateSHA256(file),
        } as IFile;
        
    }));
}
