import { numberInputAnatomy, slotRecipe } from "../recipe.anatomy";

export const numberInputSlotRecipe = slotRecipe({
  className: "chakra-number-input",
  slots: numberInputAnatomy.keys(),
  base: {
    control: {
      borderRadius: "15px",
    },
    root: {
      borderRadius: "15px",
    },
    input: {
      borderRadius: "15px",
    }
  },
});

