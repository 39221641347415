import {slotRecipe, datepickerAnatomy} from "../recipe.anatomy";

export const datePickerSlotRecipe = slotRecipe({
    className: "chakra-radio-card",
    slots: datepickerAnatomy.keys(),
    base: {
        root: {
            borderRadius: "15px"
        },
        input: {
          borderRadius: "15px"  
        },
        table: {
            borderRadius: "15px"
        }
    },
});

