import {
    Avatar,
    Card,
    DialogBackdrop,
    DialogBody,
    DialogCloseTrigger,
    DialogContent,
    DialogHeader, DialogRoot, Flex,
    Input,
    Text
} from "src/components";
import {CardHover} from "src/theme";
import {useEffect, useState} from "src/core";

export default function CustomerSearch(props: {
    onSelect: (customer: any) => void,
    onSearch: (query: string) => void,
    open: boolean,
    onClose: () => void,
    onOpen: () => void,
    type: string
}) {
    const {onSelect, onOpen, onClose, open, type} = props;
    const [query, setQuery] = useState("");
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (query.length >= 3) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            const id = setTimeout(() => {
                props.onSearch(query);
            }, 1000);
            setTimeoutId(id);
        }
    }, [query]);

    return <DialogRoot open={open} closeOnEscape>
        <DialogBackdrop/>
        <DialogContent>
            <DialogHeader>
                <DialogCloseTrigger onClick={onClose}/>
                <Text fontWeight={"bold"}>Editace {type}</Text>
            </DialogHeader>
            <DialogBody>
                <Input onChange={(e) => setQuery(e.target.value)} placeholder={"...hledat"} mb={4}/>
                <Card.Root css={CardHover.card.hover}>
                    <Card.Body>
                        <Flex
                            gap={2}
                            direction={"row"}
                            mt={4}
                            justifyContent={"space-between"}
                        >
                            <Avatar size={"sm"}/>
                            <Text fontSize={"sm"}>Jméno Příjmení</Text>
                            <Text fontSize={"sm"}></Text>
                            <Text fontSize={"sm"}>Email</Text>
                            <Text fontSize={"sm"}>Mobil</Text>
                        </Flex>
                    </Card.Body>
                </Card.Root>
            </DialogBody>
        </DialogContent>
    </DialogRoot>;
};