import logo from "../assets/img/logo.png";
import MarkdownEditor from "@uiw/react-md-editor";
import "@uiw/react-md-editor/markdown-editor.css";
import "@uiw/react-markdown-preview/markdown.css";
import {
    signalR,
    Image,
    useColorModeValue,
    useEffect,
    useState,
    useDisclosure,
    Container,
    Center,
    Flex,
    DialogRoot,
    DialogContent,
    DialogHeader,
    DialogCloseTrigger,
    DialogBody,
    Button,
    Text,
    MenuTrigger, MenuRoot, Avatar, Alert, Fa, ColorModeButton, MenuContent, MenuItem, Lu,
} from "src/items";
import {useRedux} from "src/redux/provider";
import {ReactNode} from "react";
import {__AuthToken} from "src/config";

export default function HeadIframePage({
                                           title,
                                           return_link,
                                           children,
                                       }: {
    title: string;
    return_link: string;
    children: ReactNode;
}) {
    const {open, onOpen, onClose} = useDisclosure();
    const [info, setInfo] = useState(true);
    const mode = useColorModeValue("light", "dark");
    const {useStore, dispatch, actions} = useRedux();

    useEffect(() => {
        dispatch(actions.shared.signalR.getHelp("/webhelp/webhelp/-/raw/main/service_request.md"));
    }, []);

    return (
        <>
            <DialogRoot open={open} size={"cover"}>
                <DialogContent>
                    <DialogHeader>
                        <DialogCloseTrigger onClick={onClose}/>
                    </DialogHeader>
                    <DialogBody data-color-mode={mode}>
                        <MarkdownEditor.Markdown
                            style={{height: "auto", maxHeight: "720px", overflow: "auto"}}
                            source={useStore.shared.help}
                        />
                    </DialogBody>
                </DialogContent>
            </DialogRoot>
            <Container maxW="container.xl">
                <Center mb={6} mt={6} justifyContent={"space-between"}>
                    <Flex>
                        <Image src={logo} alt="Logo" w="200px"/>
                    </Flex>
                    <Flex gap={4} align={"center"}>
                        <Button
                            variant={"ghost"}
                            display={useStore.shared.e_mail ? "flex" : "none"}
                            disabled
                        >
                            Nový požadavek
                        </Button>
                        <Button
                            variant={"ghost"}
                            display={useStore.shared.e_mail ? "flex" : "none"}
                            disabled
                        >
                            Správa požadavků
                        </Button>
                        <MenuRoot>
                            <MenuTrigger>
                                <Button
                                    variant={"ghost"}
                                    size={"xl"}
                                    display={useStore.shared.e_mail ? "flex" : "none"}
                                    onClick={() => {
                                    }}
                                >
                                    <Avatar name={useStore.shared.e_mail}/>
                                    <Text display={{base: "none", md: "flex"}}>
                                        {useStore.shared.e_mail}
                                    </Text>
                                </Button>
                            </MenuTrigger>
                            <MenuContent key={"menu_content"}>
                                <MenuItem
                                    key="logout_button"
                                    value="Odhlásit"
                                    onClick={() => {
                                        localStorage.removeItem(__AuthToken);
                                        window.location.reload();
                                    }}
                                >
                                    <Lu.LuLogOut/> Odhlásit se
                                </MenuItem>
                            </MenuContent>
                        </MenuRoot>

                        <ColorModeButton/>
                    </Flex>
                </Center>
                <Flex
                    w="100%"
                    direction={"column"}
                    align={"center"}
                    mb={{sm: 6, md: 0}}
                >
                    <Text fontSize="3xl" fontWeight="bold">
                        {title}
                    </Text>
                    {info && (
                        <Alert
                            status="info"
                            fontSize="sm"
                            mb={2}
                            closable
                            onClose={() => setInfo(false)}
                        >
                            <Flex
                                w={"100%"}
                                direction={{base: "column", md: "row"}}
                                justify={"space-between"}
                            >
                                <Text fontSize="sm">
                                    Spuštěn pilotní provoz. V případě problémů nás kontaktujte na
                                    emailu <a href="mailto:it@britex.cz">it@britex.cz</a>. Pokud
                                    si nevíte rady co a jak tak klikněte na tlačítko nápověda.
                                </Text>
                                <Button variant={"outline"} onClick={onOpen}>
                                    Nápověda
                                </Button>
                            </Flex>
                        </Alert>
                    )}
                </Flex>
                <Flex mb={4}>
                    <Button
                        gap={6}
                        variant={"ghost"}
                        onClick={() => window.location.replace(return_link)}
                    >
                        <Fa.FaArrowLeft/>
                        Zpět na BRITEX.CZ
                    </Button>
                </Flex>
                <Flex direction={"column"}>{children}</Flex>
            </Container>
        </>
    );
}
