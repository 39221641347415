import {InputGroup, useState} from "src/items";
import {Button, Card, Checkbox, Fa, Flex, Input, Lu, Text} from "src/components";
import LpCreate from "./lp.create";
import LpItem from "./lp.item";
import {CheckedChangeDetails} from "@zag-js/checkbox";
import Navigations from "src/components/ui/navigations";
import LpItemDetail from "src/views/logisticsPackages/lp.itemdetail";
import React from "react";
import {useRedux} from "src/redux/provider";

export default function LPPreview() {
    const {useStore, dispatch, actions} = useRedux();
    const [allCHecked, setAllChecked] = useState(false);
    let data = {
        is_prev: true,
        is_next: true,
        pageNumber: 1,
        pageSize: 20,
        data: [
            {
                id: 4000111,
                is_return: false,
                is_cod: false,
                cod_price: 0,
                customer: "Luboš Fridrich",
                customer_address: "Nová 182, 123 45 Praha 2",
                carrier: "DPD CZ",
                pack_code: "13654142154874",
                date: "2024-10-24",
                status: "Přijato",
                is_checked: false,
            },
            {
                id: 4000111,
                is_return: false,
                is_cod: false,
                cod_price: 0,
                customer: "Luboš Fridrich",
                customer_address: "Nová 182, 123 45 Praha 2",
                carrier: "DPD CZ",
                pack_code: "13654142154874",
                date: "2024-10-24",
                status: "Rozbaleno",
                is_checked: false,
            },
            {
                id: 4000111,
                is_return: false,
                is_cod: false,
                cod_price: 0,
                customer: "Luboš Fridrich",
                customer_address: "Nová 182, 123 45 Praha 2",
                carrier: "DPD CZ",
                pack_code: "13654142154874",
                date: "2024-10-24",
                status: "Předáno",
                is_checked: false,
            },
            {
                id: 4000111,
                is_return: false,
                is_cod: false,
                cod_price: 0,
                customer: "Luboš Fridrich",
                customer_address: "Nová 182, 123 45 Praha 2",
                carrier: "DPD CZ",
                pack_code: "13654142154874",
                date: "2024-10-24",
                status: "Neshoda",
                is_checked: false,
            },
            {
                id: 4000111,
                is_return: true,
                is_cod: true,
                cod_price: 2541.22,
                customer: "Luboš Fridrich",
                customer_address: "Nová 182, 123 45 Praha 2",
                carrier: "DPD CZ",
                pack_code: "13654142154874",
                date: "2024-10-24",
                status: "Čeká na vyzvednutí",
                is_checked: false,
            },
            {
                id: 4000111,
                is_return: true,
                is_cod: false,
                cod_price: 0,
                customer: "Luboš Fridrich",
                customer_address: "Nová 182, 123 45 Praha 2",
                carrier: "DPD CZ",
                pack_code: "13654142154874",
                date: "2024-10-24",
                status: "Odesláno",
                is_checked: false,
            },
        ],
        total: 6,
        count: 0,
        onNext: () => {
        },
        onPrev: () => {
        },
        onPageSize: (size: number) => {
        },
    };

    const handleAllChecked = (e: CheckedChangeDetails) => {
        setAllChecked(!!e.checked);
        data.data.forEach((item) => {
            item.is_checked = allCHecked;
        });
    };

    return (
        <>
            <Flex w={"100%"} direction={"column"}>
                <Card.Root w={"100%"} mb={2}>
                    <Card.Body>
                        <Flex
                            gap={2}
                            mb={2}
                            direction={"row"}
                            justifyContent={"space-between"}
                        >
                            <Flex gap={4} direction={"row"} align={"center"}>
                                <Checkbox checked={allCHecked}
                                          onCheckedChange={handleAllChecked}/>
                                <Button
                                    disabled={true}
                                    variant={"outline"}
                                    size={"sm"}
                                    alignContent={"center"}
                                >
                                    <Text mt={0}>Další akce</Text>
                                </Button>
                            </Flex>
                            <Flex gap={2} direction={"row"} mt={2}>
                                <LpCreate/>
                                <Button
                                    size={"sm"}
                                    variant={"subtle"}
                                    aria-label="Přijmout zásilky"
                                ><Lu.LuArrowDownToDot/></Button>
                            </Flex>
                            <Flex>
                                <Flex>
                                    <InputGroup startElement={<Fa.FaSearch/>}>
                                        <Input placeholder="Hledat..."/>
                                    </InputGroup>
                                </Flex>
                            </Flex>
                            {/* <SoFilter /> */}
                        </Flex>
                    </Card.Body>
                </Card.Root>
                <Navigations
                    mb={{mb: 2}}
                    type=""
                    data={data}
                    renderComponent={LpItem}
                />
            </Flex>
        </>
    );
}
