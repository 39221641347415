import Enhancer, {Generate} from "src/redux/enhancer";
import {EnhancersFrom} from "src/enums";
import {signalR} from "src/enums";

export const signalRActions = (storeName: string) => {
    return {
        LogIn: (code: string) =>
            Generate(storeName, {token: code}, "LogIn", EnhancersFrom.signalR, signalR.users.usersLoginOAuth2),
        Auth: () =>
            Generate(storeName, {is_authorize: true}, "Auth", EnhancersFrom.signalR, signalR.users.isAuthorize),
           
    }
}

export function ActionsEnhancer<T>(action: T, storeName: string) {
    return Enhancer(action, signalRActions(storeName),{}, {});
}