import {LogisticsPackages} from "src/types/logistics_packages";
export const initialState: LogisticsPackages = {
    list_packages: [],
    new_package: {
        state: false
    },
    detail: {
        state: false,
        data: null
    }
};