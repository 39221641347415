import AddressDialog from "../components/address-dialog";
import AddressDialogIco from "../components/address-dialog-ico";
import Address from "../components/address-form";
import Card from "../components/card";
import Device, { DeviceCard, DeviceEditor } from "../components/device";
import Finish from "../components/finish";
import { Beggin, ControllerForm, Stepper } from "../components/form-controll";
import Summary from "../components/summary";
import Transport from "../components/transport";
import Verification from "../components/verification";

const Interface = {
  Card,
  Device,
  DeviceCard,
  DeviceEditor,
  Address,
  Transport,
  Summary,
  Beggin,
  ControllerForm,
  Stepper,
  Verification,
  AddressDialog,
  AddressDialogIco,
  Finish
};

export default Interface;
