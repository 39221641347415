import {Package, LogisticsPackages} from "src/types/logistics_packages";


export const setOpenDetail = (state: LogisticsPackages, action: {
    payload: { state: boolean, data: Package | null }
}) => {
    state.detail.state = action.payload.state;
    state.detail.data = action.payload.data;
};


