import {createSlice} from "@reduxjs/toolkit";
import * as reducers from "./users.reducers";
import {ActionsEnhancer} from "./users.actions";
import {initialState} from "./users.init";

const name = "users";
const UsersSlice = createSlice({
    name,
    initialState,
    reducers
});

export const UsersReducer = UsersSlice.reducer;
export const UsersActions = ActionsEnhancer(UsersSlice.actions, name);
