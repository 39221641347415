import type {ButtonProps as ChakraButtonProps} from "@chakra-ui/react"
import {
    AbsoluteCenter,
    Button as ChakraButton,
    Span,
    Spinner,
} from "@chakra-ui/react"
import {forwardRef} from "react"
import {useRedux} from "src/redux/provider";

interface ButtonLoadingProps {
    loading?: boolean
    loadingText?: React.ReactNode
}

export interface ButtonProps extends ChakraButtonProps, ButtonLoadingProps {
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    function Button(props, ref) {
        const {loading, disabled, loadingText, children, ...rest} = props
        const {useStore} = useRedux()

        let is_loading = loading ?? useStore.core.loading;
        return (
            <ChakraButton disabled={is_loading || disabled} ref={ref} {...rest}>
                {is_loading && !loadingText ? (
                    <>
                        <AbsoluteCenter display="inline-flex">
                            <Spinner size="inherit" color="inherit"/>
                        </AbsoluteCenter>
                        <Span opacity={0}>{children}</Span>
                    </>
                ) : is_loading && loadingText ? (
                    <>
                        <Spinner size="inherit" color="inherit"/>
                        {loadingText}
                    </>
                ) : (
                    children
                )}
            </ChakraButton>
        )
    },
)
