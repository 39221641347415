import {useParams} from "src/items";
import {Card, Flex, Text, Fieldset, Textarea, Button, Lu, Field, Input} from "src/components";
import {useRedux} from "src/redux/provider";
import {HoverCard} from "@chakra-ui/react";

export default function SoPriceProposal() {
    const {id} = useParams();
    const {useStore} = useRedux();
    return (
        <Flex w={"100%"} direction={"column"}>
            <Flex
                flexDirection={"row"}
                justifyContent={"space-between"}
                align={"center"}
            >
                <Text fontSize={"sm"} fontWeight={"bold"}>
                    Vystavit Cenovou nabídku
                </Text>
                <Flex gap={2}>
                    <Button size={"sm"} variant={"surface"}><Lu.LuCamera/></Button>
                    <Button size={"sm"} variant={"surface"}><Lu.LuSearch/></Button>
                    <Button size={"sm"} variant={"surface"}><Lu.LuMailCheck/></Button>
                </Flex>
            </Flex>
            <Flex flexDirection={"row"} align={"center"}>
                <Text fontSize={"sm"} fontWeight={"bold"}>
                    {id}
                </Text>
            </Flex>
            <Flex direction={"column"} fontSize={"sm"}>
                <Text>
                    Tester Testovič
                </Text>
                <Text>
                    Testovací 123
                </Text>
                <Text>
                    000 00, Testovo, CZ
                </Text>
                <Text>
                    25130340
                </Text>
            </Flex>

            <Flex flexDirection={"column"} gap={4}>
                <Field mt={4} label={"Důvod cenové nabídky"}>
                    <Textarea rows={6}/>
                </Field>
                <Field mt={4} label={"Položky nabídky"}>
                    <Button w={"100%"} variant={"surface"} size={"sm"}>Přidat další položku</Button>
                    <Card.Root w={"100%"}>
                        <Card.Body>
                            <Flex justifyContent={"space-between"}>
                                <Flex direction={"column"}>
                                    <Text>
                                        Položka 1
                                    </Text>
                                    <Text fontSize={"xs"}>Popisek položky</Text>
                                </Flex>

                                <Flex justifyContent={"end"}>
                                    <Button variant={"ghost"}><Lu.LuPlus/></Button>
                                    <Input w={"20%"}/>
                                    <Button variant={"ghost"}><Lu.LuMinus/></Button>
                                    <Button variant={"ghost"}><Lu.LuTrash/></Button>
                                </Flex>
                            </Flex>

                        </Card.Body>
                    </Card.Root>
                    <Card.Root w={"100%"}>
                        <Card.Body>
                            <Text>
                                Položka 2
                            </Text>
                        </Card.Body>
                    </Card.Root>
                </Field>
            </Flex>
        </Flex>
    );
}
