import {reducer, actions as action} from "./features";
import {configureStore} from "@reduxjs/toolkit";
import {signalRMiddleware} from "src/redux/signalRMiddleware";
import {fetchMiddleware} from "src/redux/fetchMiddleware";

export const actions = action

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(signalRMiddleware, fetchMiddleware),
});
export type AppDispatch = typeof store.dispatch;
export type StoreState = ReturnType<typeof store.getState>;
export default store;
