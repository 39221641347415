import {Core} from "src/types/core";

export const initialState: Core = {
    lookups: {countries: null, vendors: null},
    loading: false,
    iframe: false,
    loading_text: "Načítám prostředí",
    signalr: {state: false, connection_id: "", message: ""},
    camera: {
        editor: {
            open: false,
            file: null,
            openDoctypeMenu: false
        },
        open: false,
        openFileViewer: false,
        openDoctypeMenu: false,
        openMobile: false,
        parentId: null,
        asChild: false,
        asNoClose: false,
        isConnectParent: false,
        isConnectChild: false,
        documentType: window.localStorage.getItem("scan-doc-type") ?? "SD",
        showEffect: false,
        loading: false,
        files: [],
        resolution: {
            height: 900,
            width: 1440, // 1440, 900
            facingMode: "environment",
        }
    },
    messages: []
};