import {navigationModel} from "src/types/core";
import {BreadcrumbLink, BreadcrumbRoot, Button, Flex, Spinner, Text} from "src/components";
import {FlexProps} from "@chakra-ui/react";
import {useRedux} from "src/redux/provider";

export default function Navigations<T>(props: {
    mb?: FlexProps;
    type: "" | "live";
    data: navigationModel<T>;
    renderComponent: Function;
}) {
    const {data, type, mb} = props;
    const {useStore} = useRedux();
    const {onPageSize, onPrev, onNext, total, pageNumber, pageSize} = data;

    const BrItem = ({pageSizeInner}: { pageSizeInner: number }) => (
        <BreadcrumbLink
            onClick={() => {
                onPageSize(pageSizeInner);
                localStorage.setItem("pageSize", pageSizeInner.toString());
            }}
        >
            {pageSizeInner}
        </BreadcrumbLink>
    );

    const NavigateMenu = () => {
        return (
            <Flex
                w="100%"
                px={1}
                align={"center"}
                mb={mb?.mb}
                justify={"space-between"}
                mt={2}
                display={type === "live" ? "none" : "flex"}
            >
                <Button
                    size={"xs"}
                    disabled={!data.is_prev}
                    variant="subtle"
                    onClick={onPrev}
                >
                    {"< Zpět"}
                </Button>
                {useStore.core.loading && <Spinner/>}
                {!useStore.core.loading && (
                    <Text fontSize={"xs"} color={"gray.500"}>
                        Nalezených záznamů:{" "}
                        {(pageNumber - 1) * pageSize + (data.count ?? 0)} / {total}
                    </Text>
                )}
                <BreadcrumbRoot separator="|" fontSize={"sm"}>
                    <BrItem pageSizeInner={20}/>
                    <BrItem pageSizeInner={50}/>
                    <BrItem pageSizeInner={100}/>
                    <BrItem pageSizeInner={200}/>
                </BreadcrumbRoot>
                <Button
                    size={"xs"}
                    disabled={!data.is_next}
                    variant="subtle"
                    onClick={onNext}
                >
                    {"Další >"}
                </Button>
            </Flex>
        );
    };

    return (
        <>
            <NavigateMenu/>
            <Flex gap={1} direction={"column"}>
                {data.data?.map((e) => props.renderComponent({item: e}))}
            </Flex>
            {!useStore.core.loading && (data.count ?? 0) > 5 && <NavigateMenu/>}
        </>
    );
}
