import React from "react";
import { Flex, Text } from "../../items";

export default function Footer() {
    return (
        <Flex
            flexDirection={{
                base: "column",
                xl: "row",
            }}
            alignItems={{
                base: "center",
                xl: "start",
            }}
            justifyContent="end"
            px="30px"
            pb="20px"
        >
            <Text
                color="gray.400"
                textAlign={{
                    base: "center",
                    xl: "start",
                }}
                mb={{ base: "20px", xl: "0px" }}
            >
                &copy; 2023 - {new Date().getFullYear()},{" "}
                <Text as="span">
                    crossui.eu
                </Text>
            </Text>
        </Flex>
    );
}
