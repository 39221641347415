import type {Shared} from "src/types/shared";

export const initialState: Shared = {
    countries: [],
    vendors: [],
    holidays: [],
    help: "",
    api_ico: null,
    is_valid_pickup_date: false,
    first_pickup_date: null,
    token_for_verify: "",
    token_verified: "",
    e_mail: "",
    is_authorized: false,
};