import {
    Avatar,
    Lu,
    TimelineConnector,
    TimelineContent,
    TimelineItem,
    TimelineRoot,
    TimelineTitle
} from "src/components";

export default function SoTimeLine() {
    return (
        <TimelineRoot size="lg" variant="subtle" maxW="md">
            <TimelineItem>
                <TimelineConnector>
                    <Lu.LuPen/>
                </TimelineConnector>
                <TimelineContent>
                    <TimelineTitle>
                        <Avatar size="2xs" src="https://i.pravatar.cc/150?u=a"/>
                        Lucas Moras <span color="fg.muted">has changed</span>
                        <span>3 labels</span> on
                        <span color="fg.muted">Jan 1, 2024</span>
                    </TimelineTitle>
                </TimelineContent>
            </TimelineItem>
        </TimelineRoot>
    );
}
