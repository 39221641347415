import {useDisclosure} from "src/items";
import {Card, Checkbox, Fa, Flex, Text} from "src/components";
import SoDetail from "./so.detail";
import {CardHover} from "src/theme";

export default function SoItem(props: { item: any }) {
    const {onOpen, onClose, open} = useDisclosure();
    const {item} = props;
    return (
        <>
            <SoDetail isOpen={open} onClose={onClose}/>
            <Card.Root css={CardHover.card.hover} variant={"subtle"} size={"sm"} onClick={onOpen}>
                <Card.Body>
                    <Flex
                        direction={"row"}
                        gap={4}
                        align={"center"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                        <Checkbox/>
                        <Flex direction={"column"}>
                            <Text fontSize={"sm"} fontWeight={"bold"}>
                                {item.id}
                            </Text>
                            <Text fontSize={"xs"}>{item.manager}</Text>
                        </Flex>
                        <Flex direction={"column"}>
                            <Text fontSize={"sm"} fontWeight={"bold"}>
                                {item.vendor}
                            </Text>
                            <Text fontSize={"xs"}>{item.dev_type}</Text>
                        </Flex>
                        <Flex direction={"column"}>
                            <Text fontSize={"sm"}>{item.dev_repair_type}</Text>
                            <Text fontSize={"xs"}>
                                {new Date().toLocaleDateString()}
                            </Text>
                        </Flex>
                        <Fa.FaArrowRight/>
                    </Flex>
                </Card.Body>
            </Card.Root>
        </>
    );
}
