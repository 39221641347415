"use client"

import {ChakraProvider, defaultSystem} from "@chakra-ui/react"
import {ColorModeProvider} from "./color-mode"
import {theme} from "src/items"
import type {ThemeProviderProps} from "next-themes/dist/types";
import {ReactElement} from "react";

export function Provider(props: React.PropsWithChildren) {

    function ValidJSXThemeProvider(props: ThemeProviderProps): ReactElement {
        return ColorModeProvider(props) as ReactElement;
    }
    
    return (
        <ChakraProvider value={theme.default}>
            <ValidJSXThemeProvider>{props.children}</ValidJSXThemeProvider>
        </ChakraProvider>
    )
}
