import {CameraFile} from "src/types/camera";
import {sendMessage} from "src/lib/signalR";

export const __server = window.location.hostname.indexOf("localhost") > -1 ? "https://localhost:7082" : "";

export function calculateRatio(a: number, b: number): string {
    if (b === 0) {
        throw new Error("Dělení nulou není povoleno.");
    }
    const gcd = (x: number, y: number): number => {
        return y === 0 ? x : gcd(y, x % y);
    };
    const divisor = gcd(a, b);
    return `${a / divisor}:${b / divisor}`;
}

export const handleGetFileName = () => {
    const date = new Date();
    return `${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`;
};

export const childPostParent = (parentId: string, file: CameraFile) => {
    fetch(`${__server}/api/SandBox/TempFile`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            content: file.content,
        }),
    }).then(r => r.json()).then((res) => {
        sendMessage("GroupInfo", {
            message: res.response,
            title: "FetchupFile",
            group: parentId
        });
    });
}