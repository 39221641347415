import {FileUploadDropzone, FileUploadList, FileUploadRoot, Flex} from "src/components";

export default function SoDetailDocuments() {
    return (
        <Flex direction={"column"} gap={2}>
            <FileUploadRoot borderRadius={"15px"} maxW="xl" alignItems="stretch" maxFiles={10}>
                <FileUploadDropzone borderRadius={"15px"}
                                    label="Drag and drop here to upload"
                                    description=".png, .jpg up to 5MB"
                />
                <FileUploadList clearable showSize/>
            </FileUploadRoot>
        </Flex>
    );
}
