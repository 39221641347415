import * as core from "./core/core.slice";
import * as shared from "./shared/shared.slice";
import * as logisticsPackages from "./logistics_packages/logistics_packages.slice";
import * as serviceRequest from "./service_request/servicerequest.slice";
import * as users from "./users/users.slice";

export const reducer = {
    users: users.UsersReducer,
    core: core.CoreReducer,
    shared: shared.SharedReducer,
    service_request: serviceRequest.ServiceRequestReducer,
    logistics_packages: logisticsPackages.LogisticsPackagesReducer,
}

export const actions = {
    core: core.CoreActions,
    users: users.UsersActions,
    shared: shared.SharedActions,
    service_request: serviceRequest.ServiceRequestActions,
    logistics_packages: logisticsPackages.LogisticsPackagesActions,
};