// Chakra Imports
import {useState, useColorModeValue, Flex, BreadcrumbRoot, Box, BreadcrumbLink, Link} from "../../items";
import NavbarLinks from "./navbarLinks";

export default function Navbar() {
    //const [scrolled, setScrolled] = useState(false);

    let mainText = useColorModeValue("gray.700", "gray.200");
    let navbarFilter = "none";
    let navbarBackdrop = "blur(21px)";
    let navbarShadow = "none";
    let navbarBg = "none";
    let navbarBorder = "transparent";
    let secondaryMargin = "0px";
    let paddingX = "15px";
   
    // const changeNavbar = () => {
    //     if (window.scrollY > 1) {
    //         setScrolled(true);
    //     } else {
    //         setScrolled(false);
    //     }
    // };
    //window.addEventListener("scroll", changeNavbar);
    return (
        <Flex
            position={"fixed"}
            boxShadow={navbarShadow}
            bg={navbarBg}
            borderColor={navbarBorder}
            filter={navbarFilter}
            backdropFilter={navbarBackdrop}
            borderWidth="1.5px"
            borderStyle="solid"
            transitionDelay="0s, 0s, 0s, 0s"
            transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
            transition-property="box-shadow, background-color, filter, border"
            transitionTimingFunction="linear, linear, linear, linear"
            alignItems={{xl: "center"}}
            borderRadius="16px"
            display="flex"
            minH="75px"
            justifyContent={{xl: "center"}}
            lineHeight="25.6px"
            mx="auto"
            mt={secondaryMargin}
            pb="8px"
            right={"30px"}
            px={{
                sm: paddingX,
                md: "30px",
            }}
            ps={{
                xl: "12px",
            }}
            pt="8px"
            top="18px"
            w={{sm: "calc(100vw - 30px)", xl: "calc(100vw - 75px - 260px)"}}
        >
            <Flex
                w="100%"
                flexDirection={{
                    sm: "column",
                    md: "row",
                }}
                alignItems={{xl: "center"}}
            >
                <Box mb={{sm: "8px", md: "0px"}}>
                    <BreadcrumbRoot>
                        <BreadcrumbLink href="/">{"RTS"}</BreadcrumbLink>
                        <BreadcrumbLink href="/">{"RTS"}</BreadcrumbLink>
                    </BreadcrumbRoot>
                    <Link
                        color={mainText}
                        bg="inherit"
                        borderRadius="inherit"
                        fontWeight="bold"
                        _active={{
                            bg: "inherit",
                            transform: "none",
                            borderColor: "transparent",
                        }}
                        _focus={{
                            boxShadow: "none",
                        }}
                    >
                        {"RTS"}
                    </Link>
                </Box>
                <Box ms="auto" w={{sm: "100%", md: "unset"}}>
                    <NavbarLinks />
                </Box>
            </Flex>
        </Flex>
    );
}
