import LpDetailCustomer from "./components/lp.detail.customer";
import LpDetailSidebar from "./components/lp.detail.sidebar";
import LpDetailEntries from "./components/lp.detail.entries";
import type {Camera} from "src/types";
import {useState} from "src/core";
import {
    Text,
    ActionBarRoot,
    ActionBarContent,
    Button,
    DrawerRoot,
    DrawerBackdrop,
    DrawerContent,
    DrawerCloseTrigger,
    DrawerHeader,
    DrawerBody,
    Grid,
    GridItem,
    DrawerFooter,
    Tooltip,
    Lu,
    Flex
} from "src/components";
import {useRedux} from "src/redux/provider";


export default function LpItemDetail() {
    const [state, setState] = useState("customer");
    const [files, setFiles] = useState<Camera.CameraFile[]>([]);
    const {dispatch, actions, useStore} = useRedux();
    const handleCapture = (file: Camera.CameraFile) => {
        setFiles((prev) => [...prev, file]);
    };

    const handleRemove = (file: Camera.CameraFile) => {
        setFiles((prev) => prev.filter((f) => f.name !== file.name));
    };

    const StateViewer = () => {
        switch (state) {
            case "customer":
                return <LpDetailCustomer />;
            case "entries":
                return <LpDetailEntries />;
            default:
                return <></>;
        }
    };

    return (
        <>
            <ActionBarRoot portalled modal open={false} closeOnEscape closeOnInteractOutside>
                <ActionBarContent zIndex={9999}>
                    <Button onClick={() => {
                    }}>
                        Zavřít
                    </Button>
                </ActionBarContent>
            </ActionBarRoot>
            <DrawerRoot open={useStore.logistics_packages.detail.state} size={"xl"}>
                <DrawerBackdrop/>
                <DrawerContent>
                    <DrawerHeader fontSize={"md"}>
                        <Text fontSize={"md"}>Detail přepravy</Text>
                        <DrawerCloseTrigger onClick={() => dispatch(actions.logistics_packages.setOpenDetail({
                            state: false,
                            data: null
                        }))}/>
                    </DrawerHeader>
                    <DrawerBody>
                        <Grid gap={2} templateColumns={"repeat(3, 1fr)"}>
                            <GridItem
                                gap={2}
                                p={4}
                                h={"100vh"}
                                borderRight={"1px solid var(--rts-colors-gray-700)"}
                            >
                                <LpDetailSidebar state={state} item={useStore.logistics_packages.detail.data!}
                                                 onChangeState={setState}/>
                            </GridItem>
                            <GridItem colSpan={2} p={4}>
                                <Flex w={"100%"}>
                                    <StateViewer/>
                                </Flex>

                            </GridItem>
                        </Grid>
                    </DrawerBody>
                    <DrawerFooter>
                        <Tooltip content={"Přidat fotografii"}>
                            <Button variant={"subtle"}
                                    onClick={() => dispatch(actions.core.setCameraOpen({
                                        state: true,
                                        files: files
                                    }))}>
                                <Lu.LuCamera/>
                            </Button>
                        </Tooltip>
                        <Tooltip content={"Tisk"}>
                            <Button variant={"subtle"}
                                    onClick={() => {
                                    }}>
                                <Lu.LuPrinter/>
                            </Button>
                        </Tooltip>
                    </DrawerFooter>
                </DrawerContent>
            </DrawerRoot>
        </>
    );
}
