import { defineSlotRecipe, RecipeVariantProps } from "@chakra-ui/react";
import { cardAnatomy, slotRecipe } from "../recipe.anatomy";

export const cardSlotRecipe = slotRecipe({
  className: "chakra-card",
  slots: cardAnatomy.keys(),
  base: {
    root: {
      borderRadius: "15px",
    },
  },
  variants: {
    variant: {
      hovered: {
        root: {
          _hover: {
            border: "1px solid gray.100",
          },
        },
      },
    },
  },
});

