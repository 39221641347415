import {useDisclosure} from "src/items";
import {
    Flex,
    InputGroup,
    Button,
    Input,
    Fa,
    Md,
    DrawerRoot,
    DrawerBackdrop,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    DrawerCloseTrigger,
    Field
} from "src/components";

export default function SoFilter() {
    const {open, onOpen, onClose} = useDisclosure();
    return (
        <>
            <Flex gap={2}>
                <InputGroup
                    startElement={<Fa.FaSearch/>}
                    endElement={
                        <Button variant={"ghost"} size={"sm"} onClick={onOpen}>
                            <Md.MdFilterList/>
                        </Button>
                    }
                >
                    <Input placeholder="Hledat..."/>
                </InputGroup>
            </Flex>

            <DrawerRoot open={open} size="md">
                <DrawerBackdrop/>
                <DrawerContent>
                    <DrawerCloseTrigger onClick={onClose}/>
                    <DrawerHeader>Vyhledávání</DrawerHeader>
                    <DrawerBody gap={2}>
                        <Flex gap={2} direction={"column"} fontSize={"xs"}>
                            <Field label="Číslo zakázky">
                                <Input placeholder="Číslo zakázky"/>
                            </Field>
                            <Field label="Uživatel">
                                <Input placeholder="Uživatel"/>
                            </Field>
                            <Field label="Stav">
                                <Input placeholder="Stav"/>
                            </Field>
                            <Field label="Druh opravy">
                                <Input placeholder="Druh opravy"/>
                            </Field>
                            <Field label="Výrobce">
                                <Input placeholder="Výrobce"/>
                            </Field>
                            <Field label="Typ přístroje">
                                <Input placeholder="Typ přístroje"/>
                            </Field>
                        </Flex>
                    </DrawerBody>
                </DrawerContent>
            </DrawerRoot>
        </>
    );
}
